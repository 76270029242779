import React from 'react';

export default function Modal({ show, onClose, title, children, footer, largura }) {
    if (!show) {
        return null;
    }

    const handleClose = () => {
        if (onClose) onClose();
    };

    return (
        <div className="modal" onClick={handleClose}>
            <div className="modalContent" onClick={e => e.stopPropagation()} style={largura ? { maxWidth: largura } : {}}>
                <div className="modalHeader">
                    <h3>{title}</h3>
                    <button type="button" className="closeButton" onClick={handleClose}>&times;</button>
                </div>
                <div className="modalBody">
                    {children}
                </div>

                <div className="modalFooter">{footer}</div>
            </div>
        </div>
    );
}