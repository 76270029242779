import React, { useState, useEffect } from 'react';
import api from '../../../Utils/services';
import Header from '../../../Components/Header';
import SideMenu from '../../../Components/SideMenu';
import Alert from '../../../Components/Alert';

export default function Configuracoes() {
    const [formData, setFormData] = useState({
        primaryColor: '',
        nomeFatura: '',
        logo: null
    });
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [alert, setAlert] = useState(false);
    const [alertText, setAlertText] = useState('');
    const [alertType, setAlertType] = useState(2);

    useEffect(() => {
        const userData = sessionStorage.getItem('userData');
        if (userData) {
            const user = JSON.parse(userData);
            const fetchData = async () => {
                try {
                    await api.get('me', user.token)
                        .then(response => {
                            setFormData({
                                primaryColor: response.data.data.primary_color || '',
                                nomeFatura: response.data.data.billing_name || '',
                                logo: response.data.data.logo || null
                            });
                        })
                        .catch(error => {
                            api.handleErros(error.response);
                        });
                } catch (error) {
                    console.error('Erro ao buscar:', error);
                    setAlertType(2);
                    setAlertText('Não foi possível carregar as configurações.');
                    setTimeout(() => {
                        setAlert(true);
                    }, 300);
                } finally {
                    setLoading(false);
                }
            };

            fetchData();
        }
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        
        if (name === 'nomeFatura') {
            // Remove espaços e caracteres especiais, mantém apenas letras e números
            const sanitizedValue = value.replace(/[^a-zA-Z0-9]/g, '').substring(0, 13);
            setFormData(prev => ({
                ...prev,
                [name]: sanitizedValue
            }));
        } else {
            setFormData(prev => ({
                ...prev,
                [name]: value
            }));
        }
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const fileURL = URL.createObjectURL(file);
            setFormData(prev => ({
                ...prev,
                logo: {
                    file: file,
                    url: fileURL
                }
            }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSaving(true);

        try {
            const userData = sessionStorage.getItem('userData');
            if (userData) {
                const user = JSON.parse(userData);
                const data = {
                    primary_color: formData.primaryColor || '#000000',
                    nome_fatura: formData.nomeFatura || ''
                };

                if (formData.logo?.file instanceof File) {
                    const reader = new FileReader();
                    reader.onloadend = async () => {
                        data.logo = reader.result; // Get base64 data without prefix
                        
                        await api.post('me', data, user.token)
                            .then(response => {
                                setAlertType(1);
                                setAlertText('Configurações atualizadas com sucesso!');
                                setTimeout(() => {
                                    setAlert(true);
                                }, 300);
                            })
                            .catch(error => {
                                if (error.response?.data?.message) {
                                    setAlertType(2);
                                    setAlertText(error.response.data.message);
                                    setTimeout(() => {
                                        setAlert(true);
                                    }, 300);
                                } else {
                                    api.handleErros(error.response);
                                }
                            });
                    };
                    reader.readAsDataURL(formData.logo.file);
                } else {
                    await api.post('me', data, user.token)
                        .then(response => {
                            setAlertType(1);
                            setAlertText('Configurações atualizadas com sucesso!');
                            setTimeout(() => {
                                setAlert(true);
                            }, 300);
                        })
                        .catch(error => {
                            if (error.response?.data?.message) {
                                setAlertType(2);
                                setAlertText(error.response.data.message);
                                setTimeout(() => {
                                    setAlert(true);
                                }, 300);
                            } else {
                                api.handleErros(error.response);
                            }
                        });
                }
            }
        } catch (error) {
            console.error('Erro ao salvar:', error);
            setAlertType(2);
            setAlertText('Não foi possível salvar as configurações.');
            setTimeout(() => {
                setAlert(true);
            }, 300);
        } finally {
            setSaving(false);
        }
    };

    if (loading) {
        return <div>Carregando...</div>;
    }

    return (
        <>
            <Alert 
                show={alert} 
                texto={alertText} 
                tipo={alertType} 
                action={() => setAlert(false)} 
            />
            <div id="wrapper">
                <SideMenu menuAtivo="meus-dados" />
                <div className="body">
                    <Header />
                    <div className="dashboard mgDefault">
                        <div className="headerBtn">
                            <h1>Configurações</h1>
                        </div>
                        <div className="panel">
                            <form onSubmit={handleSubmit}>
                                <div style={{ display: 'flex', gap: '20px', marginBottom: '20px' }}>
                                    <div style={{ flex: 1 }}>
                                        <label>Cor Primária</label>
                                        <div style={{ position: 'relative', width: '120px' }}>
                                            <input
                                                type="text"
                                                name="primaryColor"
                                                value={formData.primaryColor}
                                                onChange={handleChange}
                                                className="form-control"
                                                pattern="^#([A-Fa-f0-9]{6})$"
                                                placeholder="#000000"
                                                style={{ 
                                                    width: '100%',
                                                    paddingRight: '30px'
                                                }}
                                            />
                                            <input
                                                type="color"
                                                value={formData.primaryColor}
                                                onChange={(e) => {
                                                    handleChange({
                                                        target: {
                                                            name: 'primaryColor',
                                                            value: e.target.value.toUpperCase()
                                                        }
                                                    });
                                                }}
                                                style={{
                                                    position: 'absolute',
                                                    right: '5px',
                                                    top: '50%',
                                                    transform: 'translateY(-50%)',
                                                    width: '20px',
                                                    height: '20px',
                                                    padding: 0,
                                                    border: 'none'
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div style={{ flex: 1 }}>
                                        <label>Nome na Fatura</label>
                                        <input
                                            type="text"
                                            name="nomeFatura"
                                            value={formData.nomeFatura}
                                            onChange={handleChange}
                                            className="form-control"
                                            style={{ maxWidth: '300px' }}
                                            maxLength={13}
                                            placeholder="Apenas letras e números"
                                        />
                                    </div>
                                </div>

                                <div style={{ marginBottom: '20px' }}>
                                    <label>Logo</label>
                                    <div style={{ marginTop: '10px', display: 'flex', alignItems: 'center', gap: '20px' }}>
                                        {(formData.logo?.url || formData.logo?.url) && (
                                            <div style={{ 
                                                width: '150px', 
                                                height: '150px', 
                                                border: '1px solid #ddd',
                                                borderRadius: '4px',
                                                padding: '10px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                backgroundColor: '#f8f9fa'
                                            }}>
                                                <img 
                                                    src={formData.logo?.url || formData.logo?.url} 
                                                    alt="Logo preview" 
                                                    style={{ 
                                                        maxWidth: '100%', 
                                                        maxHeight: '100%', 
                                                        objectFit: 'contain' 
                                                    }} 
                                                />
                                            </div>
                                        )}
                                        <div>
                                            <input
                                                type="file"
                                                accept="image/*"
                                                onChange={handleFileChange}
                                                style={{ display: 'none' }}
                                                id="logo-upload"
                                            />
                                            <label 
                                                htmlFor="logo-upload" 
                                                className="btn"
                                                style={{ 
                                                    cursor: 'pointer',
                                                    display: 'inline-block',
                                                    marginBottom: '10px'
                                                }}
                                            >
                                                Escolher arquivo
                                            </label>
                                            <div style={{ fontSize: '12px', color: '#666' }}>
                                                Tamanho máximo: 2MB<br />
                                                Formatos aceitos: JPG, PNG
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <button 
                                    type="submit" 
                                    className="btn btnConfirm"
                                    style={{
                                        minWidth: '100px',
                                        height: '35px',
                                        fontSize: '13px',
                                        fontWeight: '600',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        gap: '8px',
                                        marginLeft: '0'
                                    }}
                                    disabled={saving}
                                >
                                    {saving ? (
                                        <>
                                            <div className="spinner-border spinner-border-sm" role="status" style={{ width: '14px', height: '14px' }}>
                                                <span className="visually-hidden">Salvando...</span>
                                            </div>
                                            <span>Salvando...</span>
                                        </>
                                    ) : (
                                        'Salvar'
                                    )}
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
