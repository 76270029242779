import React, { useState, useEffect } from 'react';
import api from '../../../Utils/services';
import Header from '../../../Components/Header';
import SideMenu from '../../../Components/SideMenu';
import NoResults from '../../../Components/NoResults';
import Modal from '../../../Components/Modal';
import Input from '../../../Components/Input';
import Alert from '../../../Components/Alert';
import Funcoes from '../../../Utils/functions';

export default function Taxas() {
    const [taxas, setTaxas] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showCalculadora, setShowCalculadora] = useState(false);
    const [bandeiraSelecionada, setBandeiraSelecionada] = useState('');
    const [parcelasSelecionadas, setParcelasSelecionadas] = useState(1);
    const [tipoTaxa, setTipoTaxa] = useState('cliente'); // 'cliente' ou 'vendedor'
    const [valor, setValor] = useState(0);
    const [valorFinal, setValorFinal] = useState(0);
    const [alert, setAlert] = useState(false);
    const [alertText, setAlertText] = useState('');
    const [alertType, setAlertType] = useState('');
    const [valorCalc, setValorCalc] = useState(0);
    const [parcelasCalc, setParcelasCalc] = useState(1);
    const [taxaCalc, setTaxaCalc] = useState(0);

    useEffect(() => {
        const userData = sessionStorage.getItem('userData');
        if (userData) {
            const user = JSON.parse(userData);
            const fetchData = async () => {
                try {
                    const response = await api.get('card-fees', user.token);
                    if (response.data && response.data.data) {
                        setTaxas(response.data.data);
                        if (response.data.data.length > 0) {
                            setBandeiraSelecionada(response.data.data[0].brand);
                        }
                    }
                } catch (error) {
                    api.handleErros(error.response);
                } finally {
                    setLoading(false);
                }
            };

            fetchData();
        }
    }, []);

    const tiposTaxas = [
        { key: 'credit.1x', label: 'Crédito 1x' },
        { key: 'credit.2x', label: '2x' },
        { key: 'credit.3x', label: '3x' },
        { key: 'credit.4x', label: '4x' },
        { key: 'credit.5x', label: '5x' },
        { key: 'credit.6x', label: '6x' },
        { key: 'credit.7x', label: '7x' },
        { key: 'credit.8x', label: '8x' },
        { key: 'credit.9x', label: '9x' },
        { key: 'credit.10x', label: '10x' },
        { key: 'credit.11x', label: '11x' },
        { key: 'credit.12x', label: '12x' }
    ];

    const getTaxaValue = (taxa, tipoTaxa) => {
        const [type, installment] = tipoTaxa.split('.');
        return taxa.fees[type][installment];
    };

    const calcularValor = () => {
        const taxa = taxas.find(t => t.brand === bandeiraSelecionada);
        if (!taxa) return;

        const taxaValor = getTaxaValue(taxa, `credit.${parcelasSelecionadas}x`);
        
        if (tipoTaxa === 'cliente') {
            // Calcula quanto o cliente vai pagar
            const valorComTaxa = valor * (1 + (taxaValor / 100));
            setValorFinal(valorComTaxa);
        } else {
            // Calcula quanto o vendedor vai receber
            const valorSemTaxa = valor / (1 - (taxaValor / 100));
            setValorFinal(valorSemTaxa);
        }
    };

    useEffect(() => {
        if (valor > 0) {
            calcularValor();
        }
    }, [valor, bandeiraSelecionada, parcelasSelecionadas, tipoTaxa]);

    const handleOpenCalculadora = () => {
        setValor(0);
        setValorFinal(0);
        setParcelasSelecionadas(1);
        setTipoTaxa('cliente');
        setShowCalculadora(true);
    };

    useEffect(() => {
        const calcularTaxa = () => {
            if (valorCalc <= 0) {
                setTaxaCalc(0);
                return;
            }

            const bandeiraSelecionadaObj = taxas.find(t => t.brand === bandeiraSelecionada);
            if (bandeiraSelecionadaObj) {
                const taxaCredito = bandeiraSelecionadaObj.fees.credit[`${parcelasCalc}x`];
                if (taxaCredito) {
                    setTaxaCalc((valorCalc * taxaCredito) / 100);
                }
            }
        };

        calcularTaxa();
    }, [valorCalc, parcelasCalc, taxas, bandeiraSelecionada]);

    if (loading) {
        return <div>Carregando...</div>;
    }

    return (
        <>
            <Alert show={alert} texto={alertText} tipo={alertType} action={() => setAlert(false)} />
            <Modal show={showCalculadora} title="Calculadora de Taxas" onClose={() => setShowCalculadora(false)}>
                <div className="form-group">
                    <label>Valor da Venda</label>
                    <Input 
                        type="money" 
                        className="form-control"
                        value={valorCalc} 
                        onChangeEvent={(e, masked, floatValue) => setValorCalc(floatValue)} 
                    />
                </div>

                <div className="form-group">
                    <label>Bandeira</label>
                    <select 
                        className="form-control"
                        value={bandeiraSelecionada}
                        onChange={e => setBandeiraSelecionada(e.target.value)}
                    >
                        {taxas.map(taxa => (
                            <option key={taxa.brand} value={taxa.brand}>
                                {taxa.brand}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="form-group">
                    <label>Número de Parcelas</label>
                    <select 
                        className="form-control"
                        value={parcelasCalc}
                        onChange={e => setParcelasCalc(parseInt(e.target.value))}
                    >
                        <option value={1}>1x</option>
                        <option value={2}>2x</option>
                        <option value={3}>3x</option>
                        <option value={4}>4x</option>
                        <option value={5}>5x</option>
                        <option value={6}>6x</option>
                        <option value={7}>7x</option>
                        <option value={8}>8x</option>
                        <option value={9}>9x</option>
                        <option value={10}>10x</option>
                        <option value={11}>11x</option>
                        <option value={12}>12x</option>
                    </select>
                </div>

                {valorCalc > 0 && (
                    <div className="form-group">
                        <label>Resultado</label>
                        <div className="form-control-static">
                            <p>Valor da Venda: <strong>R$ {Funcoes.float2Preco(valorCalc)}</strong></p>
                            <p>Taxa: <strong>R$ {Funcoes.float2Preco(taxaCalc)}</strong></p>
                            <p>Valor Líquido: <strong>R$ {Funcoes.float2Preco(valorCalc - taxaCalc)}</strong></p>
                        </div>
                    </div>
                )}
                <div className="modal-actions">
                    <button className="btn secondary" onClick={() => setShowCalculadora(false)}>
                        Fechar
                    </button>
                </div>
            </Modal>

            <div id="wrapper">
                <SideMenu menuAtivo="meus-dados" />
                <div className="body">
                    <Header />
                    <div className="dashboard mgDefault">
                        <div className="headerBtn">
                            <div>
                                <h1>Taxas</h1>
                            </div>
                            <button className="btn" onClick={handleOpenCalculadora}>
                                Calculadora de Taxas
                            </button>
                        </div>
                        <div className="panel">
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Tipo</th>
                                            {taxas.map(taxa => (
                                                <th key={taxa.brand}>{taxa.brand}</th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {taxas.length > 0 ? (
                                            tiposTaxas.map(tipoTaxa => (
                                                <tr key={tipoTaxa.key}>
                                                    <td>{tipoTaxa.label}</td>
                                                    {taxas.map(taxa => (
                                                        <td key={`${taxa.brand}-${tipoTaxa.key}`}>
                                                            {getTaxaValue(taxa, tipoTaxa.key)}%
                                                        </td>
                                                    ))}
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan={taxas.length + 1}>
                                                    <NoResults />
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
