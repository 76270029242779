import React, { useState, useEffect } from 'react';
import SideMenu from '../../../Components/SideMenu';
import Header from '../../../Components/Header';
import Loading from '../../../Components/LoaderScreen';
import Alert from '../../../Components/Alert';
import Modal from '../../../Components/Modal';
import Input from '../../../Components/Input';
import ApiService from '../../../Utils/services';
import { Funcoes } from '../../../Utils/functions';
import NoResults from '../../../Components/NoResults';
import Pagination from '../../../Components/Pagination';

import iconLixeira from '../../../resources/img/icon-lixeira.svg';
import iconVisualizar from '../../../resources/img/icon-visualizar.svg';

export default function ReguasCobranca() {
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState(false);
    const [alertText, setAlertText] = useState('');
    const [alertType, setAlertType] = useState(2);
    const [regras, setRegras] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [showModal, setShowModal] = useState(false);
    const [editingRegra, setEditingRegra] = useState(null);
    const [vcob_canal, setVcobCanal] = useState(1);
    const [vcob_tipo, setVcobTipo] = useState(1);
    const [vcob_dias, setVcobDias] = useState('');
    const [vcob_mensagem, setVcobMensagem] = useState('');

    useEffect(() => {
        loadRegras(1);
    }, []);

    const loadRegras = (page) => {
        setLoading(true);
        const userData = JSON.parse(sessionStorage.getItem("userData"));
        
        ApiService.get(`vendedor-cobrancas?page=${page}`, userData.token)
            .then(result => {
                setRegras(result.data.data);
                setTotalPages(result.data.last_page || 1);
                setCurrentPage(page);
            })
            .catch(error => {
                console.error('Erro ao carregar réguas:', error);
                setAlertType(2);
                setAlertText('Erro ao carregar réguas de cobrança');
                setAlert(true);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleSubmit = () => {
        if (!vcob_canal || !vcob_tipo || !vcob_dias || !vcob_mensagem) {
            setAlertType(2);
            setAlertText('Preencha todos os campos obrigatórios!');
            setAlert(true);
            return;
        }

        setLoading(true);
        const userData = JSON.parse(sessionStorage.getItem("userData"));
        const data = {
            vcob_canal,
            vcob_tipo,
            vcob_dias,
            vcob_mensagem
        };

        const request = editingRegra
            ? ApiService.put(`vendedor-cobrancas/${editingRegra.vcob_id}`, data, userData.token)
            : ApiService.post('vendedor-cobrancas', data, userData.token);

        request
            .then(() => {
                setAlertType(1);
                setAlertText(editingRegra ? 'Régua de cobrança atualizada com sucesso!' : 'Régua de cobrança criada com sucesso!');
                setAlert(true);
                clearForm();
                setShowModal(false);
                loadRegras(currentPage);
            })
            .catch(error => {
                console.error('Erro ao salvar régua:', error);
                setAlertType(2);
                setAlertText('Erro ao salvar régua de cobrança');
                setAlert(true);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleEdit = (regra) => {
        setEditingRegra(regra);
        setVcobCanal(regra.vcob_canal);
        setVcobTipo(regra.vcob_tipo);
        setVcobDias(regra.vcob_dias);
        setVcobMensagem(regra.vcob_mensagem);
        setShowModal(true);
    };

    const handleDelete = (id) => {
        if (window.confirm('Tem certeza que deseja excluir esta régua de cobrança?')) {
            setLoading(true);
            const userData = JSON.parse(sessionStorage.getItem("userData"));

            ApiService.delete(`vendedor-cobrancas/${id}`, userData.token)
                .then(() => {
                    setAlertType(1);
                    setAlertText('Régua de cobrança excluída com sucesso!');
                    setAlert(true);
                    loadRegras(currentPage);
                })
                .catch(error => {
                    console.error('Erro ao excluir régua:', error);
                    setAlertType(2);
                    setAlertText('Erro ao excluir régua de cobrança');
                    setAlert(true);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    const clearForm = () => {
        setEditingRegra(null);
        setVcobCanal(1);
        setVcobTipo(1);
        setVcobDias('');
        setVcobMensagem('');
    };

    const getTipoText = (tipo) => {
        return tipo === 1 ? 'Lembrete' : 'Cobrança';
    };

    const getCanalText = (canal) => {
        return canal === 1 ? 'E-mail' : 'WhatsApp';
    };

    const getTipoHelperText = (tipo) => {
        return tipo === 1 
            ? 'Lembrete: indica que a notificação vai ser enviada X dias ANTES, onde X são os dias preenchidos no campo abaixo.'
            : 'Cobrança: indica que a notificação vai ser enviada X dias DEPOIS, onde X são os dias preenchidos no campo abaixo.';
    };

    const stripHtml = (html) => {
        const tmp = document.createElement('div');
        tmp.innerHTML = html;
        return tmp.textContent || tmp.innerText || '';
    };

    return (
        <>
            <Loading show={loading} />
            <Alert show={alert} texto={alertText} tipo={alertType} action={() => { setAlert(false) }} />
            <Modal show={showModal} footer={<><button
                            className="btn secondary"
                            onClick={() => setShowModal(false)}
                        >
                            Cancelar
                        </button>
                        <button
                            className="btn primary"
                            onClick={handleSubmit}
                        >
                            {editingRegra ? 'Atualizar' : 'Criar'}
                        </button></>} title={editingRegra ? "Editar Régua de Cobrança" : "Nova Régua de Cobrança"} onClose={() => setShowModal(false)}>
                <div className="modalBody">
                    <div className="form-group">
                        <label>Canal de Comunicação *</label>
                        <select
                            value={vcob_canal}
                            onChange={(e) => setVcobCanal(parseInt(e.target.value))}
                            className="form-control"
                        >
                            <option value={1}>WhatsApp</option>
                            <option value={2}>Email</option>
                        </select>
                    </div>

                    <div className="form-group">
                        <label>Tipo *</label>
                        <select
                            value={vcob_tipo}
                            onChange={(e) => setVcobTipo(parseInt(e.target.value))}
                            className="form-control"
                        >
                            <option value={1}>Antes do vencimento</option>
                            <option value={2}>Após o vencimento</option>
                        </select>
                        <small className="form-text text-muted">
                            {vcob_tipo === 1 ? 'Enviar lembrete antes do vencimento' : 'Enviar cobrança após o vencimento'}
                        </small>
                    </div>

                    <div className="form-group">
                        <label>Dias *</label>
                        <Input
                            type="number"
                            value={vcob_dias}
                            onChange={(e) => setVcobDias(e.target.value)}
                            placeholder="Quantidade de dias"
                            min="1"
                            className="form-control"
                        />
                    </div>

                    <div className="form-group">
                        <label>Mensagem *</label>
                        <textarea
                            value={vcob_mensagem}
                            onChange={(e) => setVcobMensagem(e.target.value)}
                            placeholder="Digite a mensagem"
                            className="form-control"
                            rows={4}
                        />
                    </div>
                </div>
            </Modal>
            <SideMenu menuAtivo={4} />

            <div className="body">
                <Header />

                <div className="regras mgDefault">
                    <div className="headerBtn">
                        <div>
                            <h1>Réguas de Cobrança</h1>
                            <p className="descricao">Gerencie suas réguas de cobrança</p>
                        </div>
                        <button className="btn" onClick={() => {
                            clearForm();
                            setShowModal(true);
                        }}>
                            Nova Régua
                        </button>
                    </div>

                    <div className="panel">
                        <div className="panelBody">
                            {loading ? (
                                <Loading />
                            ) : regras.length === 0 ? (
                                <NoResults text="Nenhuma régua de cobrança cadastrada" />
                            ) : (
                                <div className="table-responsive">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Canal</th>
                                                <th>Tipo</th>
                                                <th>Dias</th>
                                                <th className="mobile-hidden">Mensagem</th>
                                                <th className="mobile-hidden">Ações</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {regras.map(regra => (
                                                <tr key={regra.vcob_id}>
                                                    <td data-label="Canal">
                                                        {getCanalText(regra.vcob_canal)}
                                                    </td>
                                                    <td data-label="Tipo">
                                                        {getTipoText(regra.vcob_tipo)}
                                                    </td>
                                                    <td data-label="Dias">
                                                        {regra.vcob_dias} {regra.vcob_dias === 1 ? 'dia' : 'dias'}
                                                    </td>
                                                    <td className="mobile-hidden" data-label="Mensagem">
                                                        {stripHtml(regra.vcob_mensagem)}
                                                    </td>
                                                    <td data-label="Ações" className="actions">
                                                        <button
                                                            className="btn icon"
                                                            onClick={() => handleEdit(regra)}
                                                            title="Visualizar/Editar"
                                                        >
                                                            <img src={iconVisualizar} alt="Visualizar" />
                                                        </button>
                                                        <button
                                                            className="btn icon danger"
                                                            onClick={() => handleDelete(regra.vcob_id)}
                                                            title="Excluir"
                                                        >
                                                            <img src={iconLixeira} alt="Excluir" />
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            )}
                            {regras.length > 0 && (
                                <Pagination
                                    currentPage={currentPage}
                                    totalPages={totalPages}
                                    onPageChange={loadRegras}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
