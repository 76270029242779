import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';

import '../resources/css/generals.scss';
import '../resources/css/pages.scss';
import '../resources/css/responsive.scss';

import logoExossWallet from '../resources/img/logo-exosswallet.png';
import iconEye from '../resources/img/icon-eye.svg';
import iconEyeOff from '../resources/img/icon-eye-off.svg';

import Redirect from '../Components/RedirectScreen';
import Loading from '../Components/LoaderScreen';
import Alert from '../Components/Alert';
import Input from '../Components/Input';
import ApiService from '../Utils/services';
import Funcoes from '../Utils/functions';

const Login = ({ history }) => {

    const [redirect, setRedirect] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertType, setAlertType] = useState(2);
    const [alert, setAlert] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        // Verifica se já existe um token válido
        const userData = sessionStorage.getItem('userData');
        if (userData) {
            history.push('/home');
        }
    }, [history]);

    const validaCampos = async () => {
        if(email === ''){
            setAlertType(2);
            setAlertMessage('Preencha o campo email corretamente!');
            setTimeout(() => {
                setAlert(true);
            }, 300);
        }else if(Funcoes.validaEmail(email) !== true){
            setAlertType(2);
            setAlertMessage('O email digitado não é um email valido!');
            setTimeout(() => {
                setAlert(true);
            }, 300);
        }else if(password === ''){
            setAlertType(2);
            setAlertMessage('Preencha o campo senha corretamente!');
            setTimeout(() => {
                setAlert(true);
            }, 300);
        }else{
            setLoading(true);
            const formData = {
                "email": email,
                "senha": password
            };
            await ApiService.post('login', formData)
                .then(result => {
                    setLoading(false);
                    const { data: { vendedor }, token } = result.data;
                    const userData = {
                        vendedor,
                        token
                    };
                    sessionStorage.setItem('userData', JSON.stringify(userData));
                    setRedirect(true);
                })
                .catch(err => {
                    const result = ApiService.handleErros(err.response);
                    console.log(result);
                    setLoading(false);
                    setAlertMessage(result.response);
                    setTimeout(() => {
                        setAlert(true);
                    }, 300);
                })
        }
    }


    const handleSubmit = (event) => {
        if (event.key === "Enter") {
            validaCampos();
        }
    }

    return (
        <>
            <Redirect redirect={redirect} path="/home" />
            <div className="login">
                <div className="contentLogin">
                    <div className="panel">
                        <img src={logoExossWallet} width="300" alt="Logo Exoss Wallet" />

                        <div className="acessaForm">
                            <Input type="email" placeholder="E-mail" value={email} onChange={e => setEmail(e.target.value)} onKeyPress={e => { handleSubmit(e) }} />
                            <div style={{ position: 'relative' }}>
                                <Input 
                                    type={showPassword ? "text" : "password"} 
                                    placeholder="Senha" 
                                    value={password} 
                                    onChange={e => setPassword(e.target.value)} 
                                    onKeyPress={e => { handleSubmit(e) }} 
                                />
                                <button
                                    type="button"
                                    onClick={() => setShowPassword(!showPassword)}
                                    style={{
                                        position: 'absolute',
                                        right: '10px',
                                        top: '50%',
                                        transform: 'translateY(-50%)',
                                        background: 'none',
                                        border: 'none',
                                        cursor: 'pointer',
                                        padding: '5px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}
                                >
                                    <img 
                                        src={showPassword ? iconEyeOff : iconEye} 
                                        alt={showPassword ? "Ocultar senha" : "Mostrar senha"} 
                                        width="20" 
                                        height="20"
                                        style={{ marginBottom: '17px' }}
                                    />
                                </button>
                            </div>
                            <button className="btn entrar" onClick={() => validaCampos()}>Entrar</button>
                            <a href="https://cadastro.exoss.com.br/" target="_blank" rel="noopener noreferrer" className="btn cadastro">
                                Ainda não tem conta? Cadastre-se
                            </a>
                        </div>
                    </div>
                </div>
                <Loading show={loading} />
                <Alert show={alert} texto={alertMessage} tipo={alertType} action={() => setAlert(false)} />
            </div>
        </>
    )
}

export default withRouter(Login);