import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './SideMenu.css';

import logoExossWallet from '../resources/img/logo-exosswallet-preto.png';
import iconDashboard from '../resources/img/menu-dashboard.svg';
import iconAdquirencia from '../resources/img/menu-adquirencia.svg';
import iconPaymentLinks from '../resources/img/menu-payment-links.svg';
import iconAssinaturas from '../resources/img/menu-assinaturas.svg';
import iconMeusDados from '../resources/img/menu-meus-dados.svg';
import iconCobrancas from '../resources/img/menu-cobrancas.svg';

export default function SideMenu() {
    const [nomeUsuario, setNomeUsuario] = useState('');
    const [user, setUser] = useState({ data: { produtos: [] } });
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [billingActive, setBillingActive] = useState(false);
    const [recurrenceActive, setRecurrenceActive] = useState(false);
    const location = useLocation();

    useEffect(() => {
        const userData = sessionStorage.getItem('userData');
        if (userData) {
            const user = JSON.parse(userData);
            setUser(user);
            const nome = user.vendedor.apelidofantasia.split(" ");
            setNomeUsuario(nome[0]);
            setBillingActive(user.vendedor.billing_active || false);
            setRecurrenceActive(user.vendedor.recurrence_active || false);
        } else {
            setNomeUsuario('Usuário');
        }
    }, []);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const closeMenu = () => {
        if (isMobile) {
            setIsMenuOpen(false);
        }
    };

    const getMenus = () => {
        const baseMenus = [
            { nome: 'Dashboard', url: '/home', icone: iconDashboard },
            { nome: 'Links de Pagamento', url: '/payment-links', icone: iconPaymentLinks },
            { nome: 'Transações', url: '/transacoes', icone: iconAdquirencia },
        ];

        if (billingActive) {
            baseMenus.splice(1, 0, 
                { nome: 'Cobranças', url: '/cobrancas', icone: iconCobrancas },
                { nome: 'Réguas de Cobrança', url: '/reguas-cobranca', icone: iconCobrancas }
            );
        }

        if (recurrenceActive) {
            baseMenus.splice(billingActive ? 3 : 1, 0,
                { nome: 'Assinaturas', url: '/assinaturas', icone: iconAssinaturas }
            );
        }

        return baseMenus;
    };

    return (
        <>
            {isMobile && (
                <button className="hamburger-menu" onClick={toggleMenu}>
                    <span></span>
                    <span></span>
                    <span></span>
                </button>
            )}
            <div className={`sideMenuBar ${isMenuOpen ? 'active' : ''}`}>
                {isMobile && (
                    <button className="closeMenu" onClick={toggleMenu}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </button>
                )}
                <nav onClick={closeMenu}>
                    <div className="profile">
                        <img src={logoExossWallet} alt="Exoss" />
                        <p>Olá {nomeUsuario}.</p>
                    </div>
                    <div className="navigation">
                        <ul>
                            {getMenus().map((item, i) => {
                                return (
                                    <li key={i}>
                                        <Link 
                                            to={item.url} 
                                            className={location.pathname === item.url ? 'active' : ''}
                                            onClick={closeMenu}
                                        >
                                            <img src={item.icone} width="18" alt={`Ícone de ${item.nome}`} /> {item.nome}
                                        </Link>
                                    </li>
                                );
                            })}
                            <li>
                                <Link to="/meus-dados/minha-conta" className={`nav-link collapsed`} data-toggle="collapse" data-target="#collapseMeusDados"
                                    aria-expanded="true" aria-controls="collapseMeusDados">
                                    <img src={iconMeusDados} alt="Meus Dados" />
                                    <span>Meus Dados</span>
                                </Link>
                                <div id="collapseMeusDados" className="collapse" aria-labelledby="headingMeusDados"
                                    data-parent="#accordionSidebar">
                                    <div className="bg-white py-2 collapse-inner rounded">
                                        <Link className="collapse-item submenu" to="/meus-dados/minha-conta" onClick={closeMenu}>Minha Conta</Link>
                                        <Link className="collapse-item submenu" to="/meus-dados/configuracoes" onClick={closeMenu}>Configurações</Link>
                                        <Link className="collapse-item submenu" to="/meus-dados/taxas" onClick={closeMenu}>Taxas</Link>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
            {isMenuOpen && isMobile && (
                <div className="menuOverlay" onClick={toggleMenu}></div>
            )}
        </>
    )
}