import React, { useState, useEffect } from 'react';
import SideMenu from '../../../Components/SideMenu';
import Header from '../../../Components/Header';
import Loading from '../../../Components/LoaderScreen';
import Alert from '../../../Components/Alert';
import Modal from '../../../Components/Modal';
import Input from '../../../Components/Input';
import ApiService from '../../../Utils/services';
import Funcoes from '../../../Utils/functions';
import { validateCPF, validateEmail, formatCPF, formatPhone, validateCNPJ, formatCPFCNPJ } from '../../../Utils/validations';
import NoResults from '../../../Components/NoResults';
import Pagination from '../../../Components/Pagination';

import iconLixeira from '../../../resources/img/icon-lixeira.svg';
import iconVisualizar from '../../../resources/img/icon-visualizar.svg';
import iconCopy from '../../../resources/img/icon-copy.svg';

export default function Cobrancas() {
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState(false);
    const [alertText, setAlertText] = useState('');
    const [alertType, setAlertType] = useState(2);
    const [links, setLinks] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [showModal, setShowModal] = useState(false);
    const [showLinkModal, setShowLinkModal] = useState(false);
    const [paymentLink, setPaymentLink] = useState('');
    const [editingLink, setEditingLink] = useState(null);
    const [nomeRazao, setNomeRazao] = useState('');
    const [cpfCnpj, setCpfCnpj] = useState('');
    const [email, setEmail] = useState('');
    const [whatsapp, setWhatsapp] = useState('');
    const [referencia, setReferencia] = useState('');
    const [descricao, setDescricao] = useState('');
    const [vencimento, setVencimento] = useState('');
    const [valor, setValor] = useState(0);
    const [limiteRecebimento, setLimiteRecebimento] = useState(5);
    const [porcentagemMulta, setPorcentagemMulta] = useState(2);
    const [porcentagemJuros, setPorcentagemJuros] = useState(1);
    const [origemCanal, setOrigemCanal] = useState(1);
    const [observacao, setObservacao] = useState('');

    useEffect(() => {
        loadLinks(1);
    }, []);

    const loadLinks = (page) => {
        setLoading(true);
        const userData = JSON.parse(sessionStorage.getItem("userData"));
        
        ApiService.get(`cobrancas?page=${page}`, userData.token)
            .then(result => {
                setLinks(result.data.data);
                setTotalPages(result.data.meta.last_page);
                setCurrentPage(result.data.meta.current_page);
                setLoading(false);
            })
            .catch(err => {
                const result = ApiService.handleErros(err.response);
                setLoading(false);
                setAlertText(result.response);
                setTimeout(() => {
                    setAlert(true);
                }, 300);
            });
    };

    const handleCreate = () => {
        setEditingLink(null);
        clearForm();
        setShowModal(true);
    };

    const handleEdit = (cobranca) => {
        setEditingLink(cobranca);
        setNomeRazao(cobranca.nome_razao);
        setCpfCnpj(cobranca.cpf_cnpj);
        setEmail(cobranca.email);
        setWhatsapp(cobranca.whatsapp);
        setReferencia(cobranca.codigo_referencia);
        setDescricao(cobranca.descricao || '');
        setVencimento(cobranca.data_vencimento);
        setValor(cobranca.valor);
        setLimiteRecebimento(cobranca.limite_recebimento);
        setPorcentagemMulta(cobranca.porcentagem_multa);
        setPorcentagemJuros(cobranca.porcentagem_juros);
        setOrigemCanal(cobranca.origem_canal);
        setShowModal(true);
    };

    const handleDelete = (id) => {
        if (window.confirm('Tem certeza que deseja cancelar esta cobrança?')) {
            setLoading(true);
            const userData = JSON.parse(sessionStorage.getItem("userData"));
            
            ApiService.customRequest(`cobrancas/${id}/cancelar`, {}, 'POST', userData.token)
                .then(() => {
                    setAlertType(1);
                    setAlertText('Cobrança excluída com sucesso!');
                    setTimeout(() => {
                        setAlert(true);
                    }, 300);
                    loadLinks(currentPage);
                })
                .catch(err => {
                    const result = ApiService.handleErros(err.response);
                    setLoading(false);
                    setAlertText(result.response);
                    setTimeout(() => {
                        setAlert(true);
                    }, 300);
                });
        }
    };

    const clearForm = () => {
        setNomeRazao('');
        setCpfCnpj('');
        setEmail('');
        setWhatsapp('');
        setReferencia('');
        setDescricao('');
        setVencimento('');
        setValor(0);
        setLimiteRecebimento(5);
        setPorcentagemMulta(2);
        setPorcentagemJuros(1);
        setOrigemCanal(1);
        setObservacao('');
    };

    const handleSubmit = () => {
        // Validar campos obrigatórios
        if (!nomeRazao || !cpfCnpj || !vencimento || !valor || !porcentagemMulta || !porcentagemJuros) {
            setAlertType(2);
            setAlertText('Preencha todos os campos obrigatórios!');
            setTimeout(() => {
                setAlert(true);
            }, 300);
            return;
        }

        // Validar CPF/CNPJ
        if (!validateCPF(cpfCnpj) && !validateCNPJ(cpfCnpj)) {
            setAlertType(2);
            setAlertText('CPF/CNPJ inválido!');
            setTimeout(() => {
                setAlert(true);
            }, 300);
            return;
        }

        // Validar email se preenchido
        if (email && !validateEmail(email)) {
            setAlertType(2);
            setAlertText('E-mail inválido!');
            setTimeout(() => {
                setAlert(true);
            }, 300);
            return;
        }

        setLoading(true);
        const userData = JSON.parse(sessionStorage.getItem("userData"));
        const data = {
            nome_razao: nomeRazao,
            cpf_cnpj: cpfCnpj.replace(/\D/g, ''),
            email,
            whatsapp: whatsapp?.replace(/\D/g, ''),
            codigo_referencia: referencia,
            data_vencimento: vencimento,
            valor: parseFloat(valor),
            limite_recebimento: limiteRecebimento,
            tipo_cobranca: 2,
            limite_parcelas: 12,
            porcentagem_multa: porcentagemMulta,
            porcentagem_juros: porcentagemJuros,
            opcao_boleto: 1,
            opcao_cartao: 0,
            opcao_pix: 0,
            origem_canal: origemCanal,
            descricao: descricao,
            observacao: observacao
        };

        const method = editingLink ? 'PUT' : 'POST';
        const url = editingLink ? `cobrancas/${editingLink.id}` : 'cobrancas';

        ApiService.customRequest(url, data, method, userData.token)
            .then((response) => {
                setShowModal(false);
                
                const link = response.data?.data?.link;
                if (link) {
                    setPaymentLink(link);
                    setTimeout(() => {
                        setShowLinkModal(true);
                    }, 500);
                }
                
                setAlertType(1);
                setAlertText(`Cobrança ${editingLink ? 'atualizada' : 'criada'} com sucesso!`);
                setTimeout(() => {
                    setAlert(true);
                }, 300);
                
                loadLinks(currentPage);
            })
            .catch(err => {
                const result = ApiService.handleErros(err.response);
                setLoading(false);
                setAlertText(result.response);
                setTimeout(() => {
                    setAlert(true);
                }, 300);
            });
    };

    const getStatusText = (status) => {
        switch (status) {
            case 0:
                return 'Em aberto';
            case 1:
                return 'Pago';
            case 2:
                return 'Cancelado';
            default:
                return 'Desconhecido';
        }
    };

    return (
        <>
            <Loading show={loading} />
            <Alert show={alert} texto={alertText} tipo={alertType} action={() => setAlert(false)} />
            
            <Modal show={showLinkModal} title="Link de Cobrança" onClose={() => setShowLinkModal(false)}>
                <div className="modalBody">
                    <div className="form-group">
                        <div style={{ 
                            background: '#f5f5f5', 
                            padding: '15px', 
                            borderRadius: '4px',
                            wordBreak: 'break-all',
                            fontSize: '14px'
                        }}>
                            {paymentLink}
                        </div>
                    </div>
                    <div className="modal-actions">
                        <button 
                            className="btn secondary"
                            onClick={() => setShowLinkModal(false)}
                        >
                            Fechar
                        </button>
                        <button 
                            className="btn primary"
                            onClick={() => {
                                navigator.clipboard.writeText(paymentLink);
                                const btn = document.createElement('div');
                                btn.innerHTML = 'Link copiado!';
                                btn.style.position = 'fixed';
                                btn.style.top = '20px';
                                btn.style.right = '20px';
                                btn.style.background = '#28a745';
                                btn.style.color = 'white';
                                btn.style.padding = '10px';
                                btn.style.borderRadius = '4px';
                                btn.style.zIndex = '9999';
                                document.body.appendChild(btn);
                                setTimeout(() => document.body.removeChild(btn), 2000);
                                setShowLinkModal(false);
                            }}
                        >
                            Copiar Link
                        </button>
                    </div>
                </div>
            </Modal>

            <Modal show={showModal} footer={<><button 
                            className="btn secondary" 
                            onClick={() => setShowModal(false)}
                        >
                            Cancelar
                        </button>
                        <button 
                            className="btn primary" 
                            onClick={handleSubmit}
                        >
                            {editingLink ? 'Atualizar' : 'Criar'}
                        </button></>} title={editingLink ? 'Editar Cobrança' : 'Nova Cobrança'} onClose={() => setShowModal(false)}>
                <div className="modalBody">
                    <div className="form-group">
                        <label>Nome/Razão Social *</label>
                        <Input
                            type="text"
                            className="form-control"
                            value={nomeRazao}
                            maxLength={90}
                            onChange={e => setNomeRazao(e.target.value)}
                        />
                    </div>

                    <div className="form-group">
                        <label>CPF/CNPJ *</label>
                        <Input
                            type="text"
                            className="form-control"
                            value={cpfCnpj}
                            maxLength={18}
                            onChange={e => setCpfCnpj(formatCPFCNPJ(e.target.value))}
                        />
                    </div>

                    <div className="form-group">
                        <label>E-mail</label>
                        <Input
                            type="email"
                            className="form-control"
                            value={email}
                            maxLength={80}
                            onChange={e => setEmail(e.target.value)}
                        />
                    </div>

                    <div className="form-group">
                        <label>WhatsApp</label>
                        <Input
                            type="text"
                            className="form-control"
                            value={whatsapp}
                            maxLength={15}
                            onChange={e => setWhatsapp(formatPhone(e.target.value))}
                        />
                    </div>

                    <div className="form-group">
                        <label>Código de Referência</label>
                        <Input
                            type="text"
                            className="form-control"
                            value={referencia}
                            maxLength={20}
                            onChange={e => setReferencia(e.target.value)}
                        />
                    </div>

                    <div className="form-group">
                        <label>Valor *</label>
                        <Input
                            type="money"
                            className="form-control"
                            value={valor}
                            onChangeEvent={(e, masked, floatValue) => setValor(floatValue)}
                            autoComplete="off"
                            inputMode="decimal"
                        />
                    </div>

                    <div className="form-group">
                        <label>Vencimento *</label>
                        <Input
                            type="date"
                            className="form-control"
                            value={vencimento}
                            onChange={e => setVencimento(e.target.value)}
                        />
                    </div>

                    <div className="form-group">
                        <label>Porcentagem de Multa (%) *</label>
                        <Input
                            type="money"
                            className="form-control"
                            value={porcentagemMulta}
                            onChangeEvent={(e, masked, floatValue) => setPorcentagemMulta(floatValue)}
                            autoComplete="off"
                            inputMode="decimal"
                        />
                    </div>

                    <div className="form-group">
                        <label>Porcentagem de Juros (%) *</label>
                        <Input
                            type="money"
                            className="form-control"
                            value={porcentagemJuros}
                            onChangeEvent={(e, masked, floatValue) => setPorcentagemJuros(floatValue)}
                            autoComplete="off"
                            inputMode="decimal"
                        />
                    </div>

                    <div className="form-group">
                        <label>Descrição</label>
                        <Input
                            type="text"
                            className="form-control"
                            value={descricao}
                            maxLength={60}
                            onChange={e => setDescricao(e.target.value)}
                        />
                    </div>

                    <div className="form-group">
                        <label>Observações</label>
                        <Input
                            type="text"
                            className="form-control"
                            value={observacao}
                            maxLength={200}
                            onChange={e => setObservacao(e.target.value)}
                        />
                    </div>
                </div>
            </Modal>
            <SideMenu menuAtivo={5} />

            <div className="body">
                <Header />

                <div className="dashboard mgDefault">
                    <div className="headerBtn">
                        <div>
                            <h1>Cobranças</h1>
                        </div>
                        <button className="btn" onClick={handleCreate}>Nova Cobrança</button>
                    </div>

                    <div className="panel">
                        <table className="table table-responsive">
                            <thead>
                                <tr>
                                    <th>Nome/Razão Social</th>
                                    <th>CPF/CNPJ</th>
                                    <th>Código Ref.</th>
                                    <th>Descrição</th>
                                    <th>Valor</th>
                                    <th>Vencimento</th>
                                    <th>Status</th>
                                    <th className="actions">Ações</th>
                                </tr>
                            </thead>
                            <tbody>
                                {links.length > 0 ? (
                                    links.map(cobranca => (
                                        <tr key={cobranca.id}>
                                            <td data-label="Nome/Razão Social">{cobranca.nome_razao}</td>
                                            <td data-label="CPF/CNPJ">{formatCPFCNPJ(cobranca.cpf_cnpj)}</td>
                                            <td data-label="Código Ref.">{cobranca.codigo_referencia || '-'}</td>
                                            <td data-label="Descrição">{cobranca.descricao || '-'}</td>
                                            <td data-label="Valor">R$ {Funcoes.float2Preco(cobranca.valor)}</td>
                                            <td data-label="Vencimento">{Funcoes.data2Brazilian(cobranca.data_vencimento)}</td>
                                            <td data-label="Status">{getStatusText(cobranca.status)}</td>
                                            <td className="rowActions">
                                                <button className="btn btnIcon" onClick={() => handleEdit(cobranca)} style={{ marginRight: 10 }}>
                                                    <img src={iconVisualizar} alt="Editar" width="18" />
                                                </button>
                                                {cobranca.link && (
                                                    <button 
                                                        className="btn btnIcon" 
                                                        onClick={() => {
                                                            navigator.clipboard.writeText(cobranca.link);
                                                            const btn = document.createElement('div');
                                                            btn.innerHTML = 'Link copiado!';
                                                            btn.style.position = 'fixed';
                                                            btn.style.top = '20px';
                                                            btn.style.right = '20px';
                                                            btn.style.background = '#28a745';
                                                            btn.style.color = 'white';
                                                            btn.style.padding = '10px';
                                                            btn.style.borderRadius = '4px';
                                                            btn.style.zIndex = '9999';
                                                            document.body.appendChild(btn);
                                                            setTimeout(() => document.body.removeChild(btn), 2000);
                                                            setShowLinkModal(false);
                                                        }}
                                                        style={{ marginRight: 10 }}
                                                    >
                                                        <img src={iconCopy} alt="Copiar Link" width="18" />
                                                    </button>
                                                )}

                                                {cobranca.status !== 2 && (
                                                    <button className="btn btnIcon" onClick={() => handleDelete(cobranca.id)}>
                                                        <img src={iconLixeira} alt="Excluir" width="18" />
                                                    </button>
                                                )}
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="8">
                                            <NoResults />
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>

                        <Pagination 
                            currentPage={currentPage}
                            totalPages={totalPages}
                            onPageChange={(page) => {
                                setCurrentPage(page);
                                loadLinks(page);
                            }}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}
