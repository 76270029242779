import React, { useState, useEffect } from 'react';

import iconAviso from '../../../resources/img/icon-aviso-modal.svg';
import SideMenu from '../../../Components/SideMenu';
import Header from '../../../Components/Header';
import Redirect from '../../../Components/RedirectScreen';
import Loading from '../../../Components/LoaderScreen';
import Alert from '../../../Components/Alert';
import Modal from '../../../Components/Modal';
import Input from '../../../Components/Input';
import Funcoes from '../../../Utils/functions';
import ApiService from '../../../Utils/services';
import './Dashboard.css';

import { Line } from 'react-chartjs-2';

export default function Dashboard() {
    const tiposChavePix = [
        { value: "1", text: "CPF" },
        { value: "2", text: "CNPJ" },
        { value: "3", text: "E-mail" },
        { value: "4", text: "Celular" },
        { value: "5", text: "Chave Aleatória" }
    ];

    const [redirect, setRedirect] = useState(false);
    const [path, setPath] = useState('/home');
    const [modal, setModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState(false);
    const [alertText, setAlertText] = useState('');
    const [alertType, setAlertType] = useState(2);
    const [today, setToday] = useState('');
    const [filter, setFilter] = useState(1);
    const [user, setUser] = useState({data: { produtos: [] } });
    const [tipoChavePix, setTipoChavePix] = useState('');
    const [chavePix, setChavePix] = useState('');
    const [pixTitular, setPixTitular] = useState('');
    const [valor, setValor] = useState('');
    const [senha, setSenha] = useState('');
    const [saldo, setSaldo] = useState({
        saldo_disponivel: '0,00',
        saldo_bloqueado: '0,00',
        total_contas: '0,00'
    });
    const [mainChart, setMainChart] = useState({
        data: {
            labels: ['0h', '1h', '2h', '3h', '4h', '5h', '6h', '7h', '8h', '9h', '10h', '11h', '12h',
                '13h', '14h', '15h', '16h', '17h', '18h', '19h', '20h', '21h', '22h', '23h'],
            datasets: [
                {
                    label: "Entrada", fill: false, lineTension: 0.1, backgroundColor: '#44DA6A', borderColor: '#44DA6A',
                    borderCapStyle: 'butt', borderDash: [], borderDashOffset: 0.0, borderJoinStyle: 'miter', pointBorderColor: '#44DA6A',
                    pointBackgroundColor: '#44DA6A', pointBorderWidth: 0, pointHoverRadius: 5, pointHoverBackgroundColor: '#44DA6A',
                    pointHoverBorderColor: '#44DA6A', pointHoverBorderWidth: 2, pointRadius: 1, pointHitRadius: 10,
                    data: []
                },
                {
                    label: 'Saída', fill: false, lineTension: 0.1, backgroundColor: '#EB5757', borderColor: '#EB5757',
                    borderCapStyle: 'butt', borderDash: [], borderDashOffset: 0.0, borderJoinStyle: 'miter', pointBorderColor: '#EB5757',
                    pointBackgroundColor: '#EB5757', pointBorderWidth: 0, pointHoverRadius: 5, pointHoverBackgroundColor: '#EB5757',
                    pointHoverBorderColor: '#EB5757', pointHoverBorderWidth: 2, pointRadius: 1, pointHitRadius: 10,
                    data: []
                }
            ]
        },
        options: {
            showScale: true, showLines: true, legend: { display: false, },
            scales: {
                xAxes: [{ gridLines: { display: false } }],
                yAxes: [{
                    ticks: {
                        beginAtZero: true, min: 0, max: 200000,
                        callback: function (value) {
                            return value < 1000 ? value : Funcoes.nFormatter(value);
                        }
                    }
                }]
            }
        }
    })

    useEffect(() => {
        setToday(Funcoes.geraDataExtenso());
        setLoading(true);
        const userData = JSON.parse(sessionStorage.getItem("userData"));
        setUser(userData);
        carregaSaldos(userData.token);
    }, []);

    const carregaSaldos = (token) => {
        setLoading(true);
        ApiService.get('dashboard/saldo', token)
            .then(result => {
                setSaldo({
                    saldo_disponivel: Funcoes.float2Preco(result.data.saldo_disponivel),
                    saldo_bloqueado: Funcoes.float2Preco(result.data.saldo_bloqueado),
                    total_contas: Funcoes.float2Preco(result.data.total_contas)
                });
                carregaMovimentacoes(false, 1, token);
            })
            .catch(err => {
                const result = ApiService.handleErros(err.response);
                if (result.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    setPath('/');
                }
                setLoading(false);
                setAlertText(result.response);
                setTimeout(() => {
                    setAlert(true);
                }, 300);
            })
    }

    const carregaMovimentacoes = (carrega, filtro, token) => {
        if(carrega){
            setLoading(true);
        }

        if(token === undefined){
            token = user.token;
        }

        ApiService.get('dashboard/movimentacoes?periodo=' + filtro, token)
            .then(result => {
                const res = result.data;
                var horas = ['0h', '1h', '2h', '3h', '4h', '5h', '6h', '7h', '8h', '9h', '10h', '11h', '12h', '13h', '14h', '15h', '16h', '17h', '18h', '19h', '20h', '21h', '22h', '23h'];
                var labels = [];
                var entrada = [];
                var saida = [];
                var maxValue = 0;
                var maxLength = (res.saida.length > res.entrada.length ? res.saida.length : res.entrada.length);

                if (res.entrada.length > 0) {
                    if (filtro === 1 || filtro === 2) {
                        for (var i = 0; i < res.entrada.length; i++) {
                            labels.push(res.entrada[i].intervalo + "h");
                            entrada.push(res.entrada[i].valor);
                            if (res.entrada[i].valor > maxValue)
                                maxValue = res.entrada[i].valor;
                        }
                        for (var x = entrada.length; x < labels.length; x++) {
                            entrada.push(0);
                        }
                    } else {
                        for (var i = 0; i < maxLength; i++) {
                            labels.push(res.entrada[i].intervalo.split('-')[2]);
                            entrada.push(res.entrada[i].valor);
                            if (res.entrada[i].valor > maxValue)
                                maxValue = res.entrada[i].valor;
                        }
                    }
                } else {
                    if (filtro === 1 || filtro === 2) {
                        labels = horas;
                    } else {
                        if (filtro === 3) {
                            for (var i = 1; i <= 7; i++) {
                                labels.push('0' + i);
                            }
                        }
                        if (filtro === 4) {
                            for (var i = 1; i <= 30; i++) {
                                labels.push(i < 10 ? '0' + i : i);
                            }
                        }
                    }
                    for (var i = 0; i < labels.length; i++) {
                        entrada.push(0);
                    }
                }

                if (res.saida.length > 0) {
                    if (filtro === 1 || filtro === 2) {
                        for (var i = 0; i < res.saida.length; i++) {
                            if(labels.length < 1){
                                labels.push(res.saida[i].intervalo + "h");
                            }
                            saida.push(res.saida[i].valor);
                            if (res.saida[i].valor > maxValue)
                                maxValue = res.saida[i].valor;
                        }
                        for (var x = saida.length; x < labels.length; x++) {
                            saida.push(0);
                        }
                    } else {
                        for (var i = 0; i < res.saida.length; i++) {
                            if(labels.length < 1){
                                labels.push(res.saida[i].intervalo.split('-')[2]);
                            }
                            saida.push(res.saida[i].valor);
                            if (res.saida[i].valor > maxValue)
                                maxValue = res.saida[i].valor;
                        }
                    }
                } else {
                    if (filtro === 1 || filtro === 2) {
                        labels = horas;
                    } else {
                        if (filtro === 3) {
                            for (var i = 1; i <= 7; i++) {
                                labels.push('0' + i);
                            }
                        }
                        if (filtro === 4) {
                            for (var i = 1; i <= 30; i++) {
                                labels.push(i < 10 ? '0' + i : i);
                            }
                        }
                    }
                    for (var i = 0; i < labels.length; i++) {
                        saida.push(0);
                    }
                }

                if(maxValue < 100){
                    maxValue = maxValue + 10;
                }else if(maxValue < 1000){
                    maxValue = maxValue + 100;
                }else if(maxValue < 10000){
                    maxValue = maxValue + 1000;
                }

                setMainChart({
                    data: {
                        labels: labels,
                        datasets: [
                            {
                                label: "Entrada", fill: false, lineTension: 0.1, backgroundColor: '#44DA6A', borderColor: '#44DA6A',
                                borderCapStyle: 'butt', borderDash: [], borderDashOffset: 0.0, borderJoinStyle: 'miter', pointBorderColor: '#44DA6A',
                                pointBackgroundColor: '#44DA6A', pointBorderWidth: 0, pointHoverRadius: 5, pointHoverBackgroundColor: '#44DA6A',
                                pointHoverBorderColor: '#44DA6A', pointHoverBorderWidth: 2, pointRadius: 1, pointHitRadius: 10,
                                data: entrada
                            },
                            {
                                label: 'Saída', fill: false, lineTension: 0.1, backgroundColor: '#EB5757', borderColor: '#EB5757',
                                borderCapStyle: 'butt', borderDash: [], borderDashOffset: 0.0, borderJoinStyle: 'miter', pointBorderColor: '#EB5757',
                                pointBackgroundColor: '#EB5757', pointBorderWidth: 0, pointHoverRadius: 5, pointHoverBackgroundColor: '#EB5757',
                                pointHoverBorderColor: '#EB5757', pointHoverBorderWidth: 2, pointRadius: 1, pointHitRadius: 10,
                                data: saida
                            }
                        ]
                    },
                    options: {
                        showScale: true, showLines: true, legend: { display: false, },
                        scales: {
                            xAxes: [{ gridLines: { display: false } }],
                            yAxes: [{
                                ticks: {
                                    beginAtZero: true, min: 0, max: maxValue,
                                    callback: function (value) {
                                        return value < 1000 ? value : Funcoes.nFormatter(value);
                                    }
                                }
                            }]
                        }
                    }
                });
                if(carrega){
                    setLoading(false);
                }else{
                    setLoading(false);
                }
            })
            .catch(err => {
                const result = ApiService.handleErros(err.response);
                if (result.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    setPath('/');
                }
                setLoading(false);
                setAlertText(result.response);
                setTimeout(() => {
                    setAlert(true);
                }, 300);
            })
    }

    const handleFilter = (filterSelected) => {
        setFilter(filterSelected);
        carregaMovimentacoes(true, filterSelected);
    }

    const solicitarSaque = () => {
        if (valor === 0 || valor === '') {
            setAlertText('Informe o valor do saque');
            setAlert(true);
            return;
        }

        if (tipoChavePix === '') {
            setAlertText('Selecione o tipo da chave PIX');
            setAlert(true);
            return;
        }

        if (chavePix === '') {
            setAlertText('Informe a chave PIX');
            setAlert(true);
            return;
        }

        if (pixTitular === '') {
            setAlertText('Informe o nome do titular');
            setAlert(true);
            return;
        }

        if (senha === '') {
            setAlertText('Informe sua senha');
            setAlert(true);
            return;
        }

        setLoading(true);
        ApiService.post('transferencia/saquepix', {
            valor: valor,
            pix_tipo_chave: parseInt(tipoChavePix),
            pix_chave: chavePix,
            pix_titular: pixTitular,
            senha: senha
        }, user.token)
        .then(result => {
            setLoading(false);
            setModal(false);
            setAlertType(1);
            setAlertText('Saque solicitado com sucesso!');
            setTimeout(() => {
                setAlert(true);
            }, 300);
            carregaSaldos(user.token);
            limpaForm();
        })
        .catch(err => {
            setLoading(false);
            const result = ApiService.handleErros(err.response);
            setAlertText(result.response);
            setAlertType(2);
            setTimeout(() => {
                setAlert(true);
            }, 300);
        });
    }

    const limpaForm = () => {
        setValor('');
        setTipoChavePix('');
        setChavePix('');
        setPixTitular('');
        setSenha('');
    }

    const formatarChavePix = (valor) => {
        if (!valor) return '';

        switch (tipoChavePix) {
            case '1': // CPF
                return valor.replace(/\D/g, '')
                    .replace(/(\d{3})(\d)/, '$1.$2')
                    .replace(/(\d{3})(\d)/, '$1.$2')
                    .replace(/(\d{3})(\d{1,2})$/, '$1-$2')
                    .substr(0, 14);
            case '2': // CNPJ
                return valor.replace(/\D/g, '')
                    .replace(/(\d{2})(\d)/, '$1.$2')
                    .replace(/(\d{3})(\d)/, '$1.$2')
                    .replace(/(\d{3})(\d)/, '$1/$2')
                    .replace(/(\d{4})(\d)/, '$1-$2')
                    .substr(0, 18);
            case '4': // Celular
                return valor.replace(/\D/g, '')
                    .replace(/(\d{2})(\d)/, '($1) $2')
                    .replace(/(\d{5})(\d)/, '$1-$2')
                    .substr(0, 15);
            default:
                return valor;
        }
    }

    const handleChavePixChange = (e) => {
        const valorFormatado = formatarChavePix(e.target.value);
        setChavePix(valorFormatado);
    }

    const formatarValor = (valor) => {
        if (!valor) return '';
        
        // Remove tudo que não é número
        valor = valor.replace(/\D/g, '');
        
        // Converte para número e divide por 100 para ter os centavos
        const numero = parseFloat(valor) / 100;
        
        // Formata com 2 casas decimais e troca ponto por vírgula
        return numero.toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
    }

    const handleValorChange = (e) => {
        const valorFormatado = formatarValor(e.target.value);
        setValor(valorFormatado);
    }

    const handleTipoChavePixChange = (e) => {
        setTipoChavePix(e.target.value);
        setChavePix(''); // Limpa a chave quando troca o tipo
    }

    return (
        <>
            <Redirect redirect={redirect} path={path} />
            <Loading show={loading} />
            <Alert show={alert} texto={alertText} subText={alertType === 1 ? '*Solicitações realizadas após as 22h poderão ser processadas no próximo dia útil. * Solicitações realizadas antes deste horário serão realizadas em até 1 hora.' : ''} tipo={alertType} action={() => { setAlert(false); setRedirect(path ? true : false) }} />
            <Modal show={modal} footer={<>
                        <button 
                            className="btn secondary" 
                            onClick={() => setModal(false)}
                        >
                            Cancelar
                        </button>
                        <button 
                            className="btn primary"
                            onClick={solicitarSaque}
                        >
                            Confirmar
                        </button>
                </>} onClose={() => setModal(false)}>
                <div className="modalBody">
                    <div className="header">
                        <img src={iconAviso} alt="Aviso" />
                        <h2>Solicitar Saque</h2>
                    </div>

                    <div className="form-group">
                        <Input
                            label="Valor"
                            type="money"
                            value={valor}
                            onChangeEvent={(e, masked, floatValue) => setValor(floatValue)}
                            autoComplete="off"
                            inputMode="decimal"
                            placeholder="0,00"
                        />
                    </div>

                    <div className="form-group">
                        <label>Tipo de Chave PIX</label>
                        <select 
                            value={tipoChavePix}
                            onChange={handleTipoChavePixChange}
                        >
                            <option value="">Selecione</option>
                            {tiposChavePix.map(tipo => (
                                <option key={tipo.value} value={tipo.value}>
                                    {tipo.text}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="form-group">
                        <Input
                            label="Chave PIX"
                            type="text"
                            value={chavePix}
                            onChange={handleChavePixChange}
                            onPaste={handleChavePixChange}
                            onBlur={handleChavePixChange}
                            placeholder={tipoChavePix === '3' ? 'email@exemplo.com' : 
                                       tipoChavePix === '4' ? '(99) 99999-9999' : 
                                       tipoChavePix === '1' ? '999.999.999-99' :
                                       tipoChavePix === '2' ? '99.999.999/9999-99' : 
                                       'Digite sua chave PIX'}
                        />
                    </div>

                    <div className="form-group">
                        <Input
                            label="Nome do Titular"
                            type="text"
                            value={pixTitular}
                            onChange={(e) => setPixTitular(e.target.value)}
                            placeholder="Digite o nome do titular"
                            maxLength={100}
                        />
                    </div>

                    <div className="form-group">
                        <Input
                            label="Senha"
                            type="password"
                            value={senha}
                            onChange={(e) => setSenha(e.target.value)}
                            placeholder="Digite sua senha"
                        />
                    </div>
                </div>
            </Modal>
            <SideMenu menuAtivo={1} />

            <div className="body">
                <Header />

                <div className="dashboard mgDefault">
                    <div className="headerBtn">
                        <div>
                            <h1>dashboard</h1>
                            <p className="descricao">{today}</p>
                        </div>
                        <button className="btn" onClick={() => setModal(true)}>Solicitar Saque</button>
                    </div>

                    <div className="rowCenter">
                        <div className="panel" style={{ width: '50%' }}>
                            <p>Saldo Disponível</p>
                            <h3>R$ {saldo.saldo_disponivel}</h3>
                        </div>
                        <div className="panel" style={{ width: '50%' }}>
                            <p>À Liberar/Bloqueado</p>
                            <h3 className="cinza">R$ {saldo.saldo_bloqueado}</h3>
                        </div>
                    </div>

                    <h2 className="titlePanel mobile-hidden">Movimentações das Contas (entrada e saída)</h2>
                    <div className="panel mobile-hidden">
                        <div className="header">
                            <div className="filtros">
                                <button className={filter === 1 ? "btn active" : "btn"}
                                    onClick={() => handleFilter(1)}>hoje</button>
                                <button className={filter === 2 ? "btn active" : "btn"}
                                    onClick={() => handleFilter(2)}>ontem</button>
                                <button className={filter === 3 ? "btn active" : "btn"}
                                    onClick={() => handleFilter(3)}>últimos 7 dias</button>
                                <button className={filter === 4 ? "btn active" : "btn"}
                                    onClick={() => handleFilter(4)}>esse mês</button>
                            </div>
                            <div className="subtitle">
                                <span className="green"></span>
                                <p>Entrada</p>

                                <span className="red"></span>
                                <p>Saída</p>
                            </div>
                        </div>
                        <Line data={mainChart.data} options={mainChart.options} height={80} />
                    </div>
                </div>
            </div>
        </>
    )
}
