import React, { useState, useEffect, useRef } from 'react';
import SideMenu from '../../../Components/SideMenu';
import Header from '../../../Components/Header';
import Loading from '../../../Components/LoaderScreen';
import Alert from '../../../Components/Alert';
import Modal from '../../../Components/Modal';
import Input from '../../../Components/Input';
import ApiService from '../../../Utils/services';
import Funcoes from '../../../Utils/functions';
import { validateCPF, validateEmail, formatCPF, formatPhone } from '../../../Utils/validations';
import NoResults from '../../../Components/NoResults';
import Pagination from '../../../Components/Pagination';

import iconLixeira from '../../../resources/img/icon-lixeira.svg';
import iconVisualizar from '../../../resources/img/icon-visualizar.svg';
import iconCopy from '../../../resources/img/icon-copy.svg';

export default function PaymentLinks() {
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState(false);
    const [alertText, setAlertText] = useState('');
    const [alertType, setAlertType] = useState(2);
    const [links, setLinks] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [showModal, setShowModal] = useState(false);
    const [showLinkModal, setShowLinkModal] = useState(false);
    const [paymentLink, setPaymentLink] = useState('');
    const [editingLink, setEditingLink] = useState(null);
    const [descricao, setDescricao] = useState('');
    const [valor, setValor] = useState(0);
    const [maxparcsemjuros, setMaxParcSemJuros] = useState(1);
    const [datavalidade, setDataValidade] = useState(new Date().toISOString().split('T')[0]);
    const [clinome, setCliNome] = useState('');
    const [clidocumento, setCliDocumento] = useState('');
    const [cliemail, setCliEmail] = useState('');
    const [clicelular, setCliCelular] = useState('');
    const [showTooltip, setShowTooltip] = useState(false);
    const valorInputRef = useRef(null);

    useEffect(() => {
        loadLinks(1);
    }, []);

    useEffect(() => {
        if (showModal) {
            // Mostra o tooltip quando o modal abre
            setShowTooltip(true);
            
            // Remove o tooltip após 3 segundos
            const timer = setTimeout(() => {
                setShowTooltip(false);
            }, 3000);

            return () => clearTimeout(timer);
        }
    }, [showModal]);

    useEffect(() => {
        if (valorInputRef.current) {
            valorInputRef.current.blur();
        }
    }, [valor]);

    const loadLinks = (page) => {
        setLoading(true);
        const userData = JSON.parse(sessionStorage.getItem("userData"));
        
        ApiService.get(`payment-links?page=${page}`, userData.token)
            .then(result => {
                setLinks(result.data.data);
                setTotalPages(result.data.last_page);
                setCurrentPage(result.data.current_page);
                setLoading(false);
            })
            .catch(err => {
                const result = ApiService.handleErros(err.response);
                setLoading(false);
                setAlertText(result.response);
                setTimeout(() => {
                    setAlert(true);
                }, 300);
            });
    };

    const handleCreate = () => {
        setEditingLink(null);
        clearForm();
        setShowModal(true);
    };

    const handleEdit = (link) => {
        setEditingLink(link);
        setDescricao(link.lnk_descricao);
        setValor(link.lnk_valor);
        setMaxParcSemJuros(link.lnk_maxparcsemjuros);
        setDataValidade(link.lnk_datavalidade.split(' ')[0]);
        setCliNome(link.lnk_clinome);
        setCliDocumento(link.lnk_clidocumento);
        setCliEmail(link.lnk_cliemail);
        setCliCelular(link.lnk_clicelular);
        setShowModal(true);
    };

    const handleDelete = (id) => {
        if (window.confirm('Tem certeza que deseja excluir este link de pagamento?')) {
            setLoading(true);
            const userData = JSON.parse(sessionStorage.getItem("userData"));
            
            ApiService.customRequest(`payment-links/${id}`, {}, 'DELETE', userData.token)
                .then(() => {
                    setAlertType(1);
                    setAlertText('Link de pagamento excluído com sucesso!');
                    setTimeout(() => {
                        setAlert(true);
                    }, 300);
                    loadLinks(currentPage);
                })
                .catch(err => {
                    const result = ApiService.handleErros(err.response);
                    setLoading(false);
                    setAlertText(result.response);
                    setTimeout(() => {
                        setAlert(true);
                    }, 300);
                });
        }
    };

    const clearForm = () => {
        setDescricao('');
        setValor(0);
        setMaxParcSemJuros(12);
        setDataValidade(new Date().toISOString().split('T')[0]);
        setCliNome('');
        setCliDocumento('');
        setCliEmail('');
        setCliCelular('');
    };

    const handleSubmit = () => {
        // Validar campos obrigatórios
        if (!descricao || !valor || !datavalidade || !maxparcsemjuros) {
            setAlertType(2);
            setAlertText('Preencha todos os campos obrigatórios!');
            setTimeout(() => {
                setAlert(true);
            }, 300);
            return;
        }

        if (valor <= 0) {
            setAlertType(2);
            setAlertText('O valor deve ser maior que zero!');
            setTimeout(() => {
                setAlert(true);
            }, 300);
            return;
        }

        setLoading(true);
        const userData = JSON.parse(sessionStorage.getItem("userData"));
        const data = {
            tipoprodserv: 1, // Tipo produto como default
            descricao,
            valor: parseFloat(valor),
            maxparcsemjuros,
            datavalidade,
            clinome,
            clidocumento: clidocumento.replace(/\D/g, ''), // Remove máscara antes de enviar
            cliemail,
            clicelular: clicelular.replace(/\D/g, '') // Remove máscara antes de enviar
        };

        const method = editingLink ? 'PUT' : 'POST';
        const url = editingLink ? `payment-links/${editingLink.lnk_id}` : 'payment-links';

        ApiService.customRequest(url, data, method, userData.token)
            .then((response) => {
                console.log('API Response:', response.data);
                
                // Primeiro fecha o modal de edição
                setShowModal(false);
                
                // Verifica se o link está em diferentes lugares possíveis na resposta
                const link = response.data?.link || response.data?.data?.link || response.data?.lnk_link;
                
                if (link) {
                    console.log('Setting payment link:', link);
                    setPaymentLink(link);
                    // Mostra o modal do link depois de um pequeno delay
                    setTimeout(() => {
                        setShowLinkModal(true);
                    }, 500);
                } else {
                    console.log('No link found in response');
                }
                
                // Mostra a mensagem de sucesso
                setAlertType(1);
                setAlertText(`Link de pagamento ${editingLink ? 'atualizado' : 'criado'} com sucesso!`);
                setTimeout(() => {
                    setAlert(true);
                }, 300);
                
                loadLinks(currentPage);
            })
            .catch(err => {
                const result = ApiService.handleErros(err.response);
                setLoading(false);
                setAlertText(result.response);
                setTimeout(() => {
                    setAlert(true);
                }, 300);
            });
    };

    const handleValorChange = (e, masked, floatValue) => {
        setValor(floatValue);
    };

    return (
        <>
            <Loading show={loading} />
            <Alert show={alert} texto={alertText} tipo={alertType} action={() => setAlert(false)} />
            
            <Modal show={showModal} footer={
                <>
                    <button 
                        className="btn secondary" 
                        onClick={() => setShowModal(false)}
                    >
                        Cancelar
                    </button>
                    <button 
                        className="btn primary"
                        onClick={handleSubmit}
                    >
                        {editingLink ? 'Atualizar' : 'Criar'}
                    </button>
                </>
            } title={editingLink ? 'Editar Link de Pagamento' : 'Novo Link de Pagamento'} onClose={() => setShowModal(false)}>
                    <div className="form-group">
                        <label>Descrição *</label>
                        <Input 
                            type="text" 
                            className="form-control"
                            value={descricao}
                            onChange={e => setDescricao(e.target.value)}
                            placeholder="Digite a descrição do produto"
                        />
                    </div>

                    <div className="form-group">
                        <label>Valor *</label>
                        <Input 
                            type="money"
                            className="form-control"
                            value={valor}
                            onChangeEvent={(e, masked, floatValue) => setValor(floatValue)}
                            autoComplete="off"
                            inputMode="decimal"
                            ref={valorInputRef}
                        />
                    </div>

                    <div className="form-group">
                        <label>Máximo de Parcelas sem Juros *</label>
                        <select 
                            className="form-control"
                            value={maxparcsemjuros}
                            onChange={e => setMaxParcSemJuros(parseInt(e.target.value))}
                        >
                            <option value={1}>1x</option>
                            <option value={2}>2x</option>
                            <option value={3}>3x</option>
                            <option value={4}>4x</option>
                            <option value={5}>5x</option>
                            <option value={6}>6x</option>
                            <option value={7}>7x</option>
                            <option value={8}>8x</option>
                            <option value={9}>9x</option>
                            <option value={10}>10x</option>
                            <option value={11}>11x</option>
                            <option value={12}>12x</option>
                        </select>
                    </div>

                    <div className="form-group">
                        <label>Data de Validade *</label>
                        <Input 
                            type="date" 
                            className="form-control"
                            value={datavalidade}
                            onChange={e => setDataValidade(e.target.value)}
                            min={new Date().toISOString().split('T')[0]}
                        />
                    </div>

                    <div className="form-group">
                        <label>Nome do Cliente</label>
                        <Input 
                            type="text" 
                            className="form-control"
                            value={clinome}
                            onChange={e => setCliNome(e.target.value)}
                            placeholder="Nome do cliente (opcional)"
                        />
                    </div>

                    <div className="form-group">
                        <label>Documento do Cliente</label>
                        <Input 
                            type="text" 
                            className="form-control"
                            value={clidocumento}
                            onChange={e => setCliDocumento(e.target.value)}
                            placeholder="CPF/CNPJ do cliente (opcional)"
                        />
                    </div>

                    <div className="form-group">
                        <label>E-mail do Cliente</label>
                        <Input 
                            type="email" 
                            className="form-control"
                            value={cliemail}
                            onChange={e => setCliEmail(e.target.value)}
                            placeholder="E-mail do cliente (opcional)"
                        />
                    </div>

                    <div className="form-group">
                        <label>Celular do Cliente</label>
                        <Input 
                            type="text" 
                            className="form-control"
                            value={clicelular}
                            onChange={e => setCliCelular(formatPhone(e.target.value))}
                            placeholder="Celular do cliente (opcional)"
                        />
                    </div>
            </Modal>

            <Modal show={showLinkModal} title="Link de Pagamento" onClose={() => setShowLinkModal(false)}>
                <div className="modalBody">
                    <div className="form-group">
                        <div style={{ 
                            background: '#f5f5f5', 
                            padding: '15px', 
                            borderRadius: '4px',
                            wordBreak: 'break-all',
                            fontSize: '14px'
                        }}>
                            {paymentLink}
                        </div>
                    </div>
                    <div className="modal-actions">
                        <button 
                            className="btn secondary" 
                            onClick={() => setShowLinkModal(false)}
                        >
                            Fechar
                        </button>
                        <button 
                            className="btn primary"
                            onClick={() => {
                                navigator.clipboard.writeText(paymentLink);
                                const btn = document.createElement('div');
                                btn.innerHTML = 'Link copiado!';
                                btn.style.position = 'fixed';
                                btn.style.top = '20px';
                                btn.style.right = '20px';
                                btn.style.background = '#28a745';
                                btn.style.color = 'white';
                                btn.style.padding = '10px';
                                btn.style.borderRadius = '4px';
                                btn.style.zIndex = '9999';
                                document.body.appendChild(btn);
                                setTimeout(() => document.body.removeChild(btn), 2000);
                            }}
                        >
                            Copiar Link
                        </button>
                    </div>
                </div>
            </Modal>
            <SideMenu menuAtivo={5} />

            <div className="body">
                <Header />

                <div className="dashboard mgDefault">
                    <div className="headerBtn">
                        <div>
                            <h1>Links de Pagamento</h1>
                        </div>
                        <button className="btn" onClick={handleCreate}>Novo Link</button>
                    </div>

                    <div className="panel">
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Descrição</th>
                                        <th>Valor</th>
                                        <th>Cliente</th>
                                        <th className="mobile-hidden">Validade</th>
                                        <th className="mobile-hidden">Ações</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {links.length > 0 ? (
                                        links.map(link => (
                                            <tr key={link.lnk_id}>
                                                <td data-label="Descrição">{link.lnk_descricao}</td>
                                                <td data-label="Valor">R$ {Funcoes.float2Preco(link.lnk_valor)}</td>
                                                <td data-label="Cliente">{link.lnk_clinome}</td>
                                                <td className="mobile-hidden" data-label="Validade">{Funcoes.geraDataExtenso(link.lnk_datavalidade.split(' ')[0])}</td>
                                                <td className="rowActions" data-label="Ações">
                                                    <button className="btn btnIcon" onClick={() => handleEdit(link)} style={{ marginRight: 10 }}>
                                                        <img src={iconVisualizar} alt="Editar" width="18" />
                                                    </button>
                                                    <button className="btn btnIcon" onClick={() => handleDelete(link.lnk_id)}>
                                                        <img src={iconLixeira} alt="Excluir" width="18" />
                                                    </button>
                                                    <button className="btn btnIcon" onClick={() => {
                                                        setPaymentLink(link.link);
                                                        setShowLinkModal(true);
                                                    }}>
                                                        <img src={iconCopy} alt="Copiar Link" width="18" />
                                                    </button>
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <NoResults />
                                    )}
                                </tbody>
                            </table>
                        </div>
                        <Pagination 
                            currentPage={currentPage}
                            totalPages={totalPages}
                            onPageChange={(page) => {
                                setCurrentPage(page);
                                loadLinks(page);
                            }}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}
