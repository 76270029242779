import React, { useState, useRef, useEffect, forwardRef } from 'react';
import InputMask from "react-input-mask";

const formatMoney = (value) => {
    if (!value && value !== "0") return "";
    
    // Remove tudo que não é número
    value = value.replace(/\D/g, "");
    
    // Adiciona os zeros necessários no início
    while (value.length < 3) {
        value = "0" + value;
    }
    
    // Formata como moeda (R$ 1.234,56)
    const reais = value.slice(0, -2);
    const centavos = value.slice(-2);
    
    let formatted = reais.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    if (formatted === "") formatted = "0";
    
    return formatted + "," + centavos;
};

const MoneyInput = forwardRef(({ label, divStyle, value, onChangeEvent, ...otherProps }, ref) => {
    const handleChange = (e) => {
        const rawValue = e.target.value;
        const formattedValue = formatMoney(rawValue);
        const numericValue = parseFloat(formattedValue.replace(/\./g, "").replace(",", ".")) || 0;
        
        e.target.value = formattedValue;
        if (onChangeEvent) {
            onChangeEvent(e, formattedValue, numericValue);
        }
    };

    return (
        <div className="inputDefault" style={divStyle}>
            {label ? (
                <label style={{ color: label === "a" ? 'transparent' : '#4F4F4F' }}>{label}</label>
            ) : null}
            <input
                type="text"
                value={value !== undefined && value !== null ? formatMoney(String(Math.round(value * 100))) : ""}
                onChange={handleChange}
                {...otherProps}
                ref={ref}
            />
        </div>
    );
});

export default function Input({ type, label, textHelper, optionsData, pegaArquivo, multiFiles, divStyle, ...otherProps }) {

    const [inputText, setInputText] = useState('');

    const getFile = (e) => {
        setInputText(e[0] ? e[0].name : '');
    }

    const renderInput = () => {
        if (type === "select") {
            return (
                <div className="inputDefault" style={divStyle}>
                    {label ? (
                        <label style={{ color: label === "a" ? 'transparent' : '#4F4F4F' }}>{label}</label>
                    ) : null}
                    <select {...otherProps}>
                        {optionsData.map((item) => {
                            return (
                                <option key={item.value} value={item.value}>{item.text}</option>
                            );
                        })}
                    </select>
                    {textHelper ? (
                        <p style={{ marginTop: -20, marginBottom: 23, color: '#EA5353' }}>* {textHelper}</p>
                    ) : null}
                </div>
            );
        } else if (type === "file") {
            return (
                <div className="inputDefault" style={divStyle}>
                    {label ? (
                        <label>{label}</label>
                    ) : null}
                    <input type="file" id="selectedFile" onChange={(e) => {
                        getFile(e.target.files);
                        pegaArquivo(e.target.files);
                    }} {...otherProps} />
                    <button type="button" onClick={() => document.querySelector(multiFiles ? multiFiles : '#selectedFile').click()} className="btnInput"
                        style={{ minWidth: 460, height: 50, background: '#FFF', borderRadius: 5, padding: '2px 3px', border: '1px solid #DFDFDF' }} >
                        <span style={{ fontSize: 16, lineHeight: '46px', paddingLeft: 10, paddingRight: 10 }}>{inputText}</span>
                        <span style={{ float: 'right', background: '#DDD', padding: '8px 11.3px', borderRadius: 5, fontSize: 24 }}>...</span>
                    </button>
                </div>
            )
        } else if (type === "checkbox") {
            return (
                <>
                    <div className="inputDefault" style={divStyle}>
                        <label style={{ display: 'flex', alignItems: 'center', fontSize: 14, color: '#4F4F4F' }}>
                            <input type="checkbox" style={{ marginRight: 9, marginLeft: 0, /* width: 17, height: 17 */ }} {...otherProps} /> {label}
                        </label>
                        {textHelper ? (
                            <p style={{ marginTop: -20, marginBottom: 23, color: '#EA5353' }}>* {textHelper}</p>
                        ) : null}
                    </div>
                </>
            )

        } else if (type === "money") {
            return <MoneyInput label={label} divStyle={divStyle} {...otherProps} />;
        } else if (type === "cpf") {
            return (
                <div className="inputDefault" style={divStyle}>
                    {label ? (
                        <label style={{ color: label === "a" ? 'transparent' : '#4F4F4F' }}>{label}</label>
                    ) : null}
                    <InputMask mask="999.999.999-99" {...otherProps} />
                </div>
            )
        } else {
            return (
                <div className="inputDefault" style={divStyle}>
                    {label ? (
                        <label style={{ color: label === "a" ? 'transparent' : '#4F4F4F' }}>{label}</label>
                    ) : null}
                    <input type={type} {...otherProps} />
                    {textHelper ? (
                        <p style={{ marginTop: -20, marginBottom: 23, color: '#EA5353' }}>* {textHelper}</p>
                    ) : null}
                </div>
            );
        }
    }

    return (
        <>
            {renderInput()}
        </>
    );
}