import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import iconSenha from '../../../resources/img/icon-senha.svg';
import SideMenu from '../../../Components/SideMenu';
import Header from '../../../Components/Header';
import Redirect from '../../../Components/RedirectScreen';
import Loading from '../../../Components/LoaderScreen';
import Alert from '../../../Components/Alert';
import Table from '../../../Components/Table';
import Input from '../../../Components/Input';
import ApiService from '../../../Utils/services';
import Funcoes from '../../../Utils/functions';
import Paginacao from '../../../Components/Paginacao';
import Modal from '../../../Components/Modal';
import { dataBrMask } from '../../../Utils/mask';

export default function CardStatementDetails(props) {

    const [redirect, setRedirect] = useState(false);
    const [path, setPath] = useState('');
    const [loading, setLoading] = useState(false);
    const [modal, setModal] = useState(false);
    const [alert, setAlert] = useState(false);
    const [alertText, setAlertText] = useState('');
    const [alertType, setAlertType] = useState(2);
    const [transacao, setTransacao] = useState({});
    const [senha, setSenha] = useState('');

    const user = JSON.parse(sessionStorage.getItem("userData"));

    const status_transacao = ["Pendente", "Finalizada", "Cancelada", "Estornada", "Não Autorizada", "Em processamento"];
    const classe_transacao = ["grey", "green", "grey", "grey", "red", "grey"];
    const tipo_operacao = ["", "Débito", "Crédito", "Boleto", "Pix"];

    useEffect(() => {
        const id = props.location.state.id;
        carregaTransacao(id);
    }, []);

    const carregaTransacao = (id) => {
        setLoading(true);
        var url = 'transacao/transacao/' + id;
        ApiService.get(url, user.token)
            .then(result => {
                const response = result.data;
                const dados = [];
                var data = response.data.datahora.split(" ");
                var hora = data[1].split(":");
                setTransacao({
                    id: response.data.id,
                    paymentid: response.data.paymentid,
                    tipo: tipo_operacao[response.data.tipo_operacao],
                    data: Funcoes.data2Brazilian(data[0]) + ' ' + hora[0] + ':' + hora[1],
                    codref: response.data.codref || '-',
                    status: status_transacao[response.data.status],
                    status_id: response.data.status,
                    classe: classe_transacao[response.data.status],
                    final_cartao: response.data.finalcartao ? "**** "+response.data.finalcartao : null,
                    parcelamento: response.data.parcelas + "x",
                    valorbruto: response.data.valor,
                    taxas: response.data.taxas,
                    liquido: response.data.valor_liquido,
                    liberacao: Funcoes.data2Brazilian(response.data.liberacao)
                });
                setLoading(false);
            })
            .catch(err => {
                console.log(err);
                const result = ApiService.handleErros(err.response);
                if (result.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    setPath('/');
                }
                setLoading(false);
                setAlertText(result.response);
                setTimeout(() => {
                    setAlert(true);
                }, 300);
            })
    }

    const cancelar = () => {
        if(senha === ''){
            setAlertType(2);
            setAlertText("Preencha o campo senha corretamente");
            setAlert(true);
        }else{
            setLoading(true);
            ApiService.post('transacao/cancelar', {payment_id: transacao.paymentid, senha: senha}, user.token)
                .then(result => {
                    const response = result.data;
                    setLoading(false);
                    setAlertType(1); 
                    setAlertText('Transação cancelada com sucesso.'); 
                    setModal(false); 
                    setAlert(true);
                })
                .catch(err => {
                    console.log(err);
                    const result = ApiService.handleErros(err.response);
                    if (result.status === "unauthorized") {
                        sessionStorage.removeItem('userData');
                        setPath('/');
                    }
                    setLoading(false);
                    setAlertText(result.response);
                    setTimeout(() => {
                        setAlert(true);
                    }, 300);
                })
        }
    }

    return (
        <>
            <Redirect redirect={redirect} path={path} />
            <Loading show={loading} />
            <Alert show={alert} texto={alertText} tipo={alertType} action={() => setAlert(false)} />
            <Modal show={modal} footer={<>
                    <button 
                        className="btn secondary" 
                        onClick={() => setModal(false)}
                    >
                        Fechar
                    </button>
                    <button 
                        className="btn primary"
                        onClick={cancelar}
                        disabled={!senha}
                    >
                        Confirmar Cancelamento
                    </button>
                </>} title="Detalhes da Transação" onClose={() => setModal(false)}>
                <div className="modalBody">
                    <div className="form-group">
                        <label><strong>Tipo da Operação</strong></label>
                        <div className="form-control-static" style={{ fontWeight: 'bold' }}>
                            {transacao.tipo}
                        </div>
                    </div>
                    <div className="form-group">
                        <label><strong>ID</strong></label>
                        <div className="form-control-static" style={{ fontWeight: 'bold' }}>
                            {transacao.id}
                        </div>
                    </div>
                    <div className="form-group">
                        <label>Data</label>
                        <div className="form-control-static">
                            {Funcoes.geraDataExtenso(transacao.datahora)}
                        </div>
                    </div>
                    <div className="form-group">
                        <label>Cód. Referência</label>
                        <div className="form-control-static">
                            {transacao.codref}
                        </div>
                    </div>
                    {transacao.final_cartao && transacao.tipo === 'Crédito' && (
                        <div className="form-group">
                            <label>Final do Cartão</label>
                            <div className="form-control-static">
                                {transacao.final_cartao}
                            </div>
                        </div>
                    )}
                    <div className="form-group">
                        <label>Valor</label>
                        <div className="form-control-static">
                            R$ {Funcoes.float2Preco(transacao.valorbruto)}
                        </div>
                    </div>
                    <div className="form-group">
                        <label>Parcelas</label>
                        <div className="form-control-static">
                            {transacao.parcelamento}
                        </div>
                    </div>

                    <div className="form-group" style={{ marginTop: '20px', borderTop: '1px solid #eee', paddingTop: '20px' }}>
                        <label>Senha</label>
                        <div className="form-control-static">
                            <Input
                                type="password"
                                value={senha}
                                onChange={e => setSenha(e.target.value)}
                                placeholder="Digite sua senha para confirmar"
                            />
                        </div>
                    </div>
                </div>
            </Modal>
            <SideMenu menuAtivo={4} />

            <div className="body">
                <Header />

                <div className="cardStatement mgDefault">
                    <div className="headerBtn">
                        <div>
                            <h1>Detalhes da transação</h1>
                            <p className="descricao">Informamos abaixo os dados da transação selecionada.</p>
                        </div>
                        {transacao.status_id === 1 ? 
                            (<>
                                <button className="btn" onClick={() => setModal(true)}>Cancelar Transação</button>
                            </>)
                        : ''}
                    </div>

                    <div className="panel">
                        <div className="panelHeader">
                            <h3>Informações</h3>
                        </div>
                        <div className="panelBody">
                            <div className="rowCenter" style={{ 
                                display: 'flex', 
                                flexWrap: 'wrap',
                                gap: '20px'
                            }}>
                                <div className="info" style={{ flex: '1', minWidth: '200px' }}>
                                    <p><strong>Tipo</strong></p>
                                    <h3 style={{ fontWeight: 'bold' }}>{transacao.tipo}</h3>
                                </div>
                                <div className="info" style={{ flex: '1', minWidth: '200px' }}>
                                    <p><strong>ID</strong></p>
                                    <h3 style={{ fontWeight: 'bold' }}>{transacao.id}</h3>
                                </div>
                                <div className="info" style={{ flex: '1', minWidth: '200px' }}>
                                    <p>Data da Transação</p>
                                    <h3>{transacao.data}</h3>
                                </div>
                            </div>

                            <div className="rowCenter" style={{ 
                                display: 'flex', 
                                flexWrap: 'wrap',
                                gap: '20px',
                                marginTop: '20px'
                            }}>
                                <div className="info" style={{ flex: '1', minWidth: '200px' }}>
                                    <p>Cód. Referência</p>
                                    <h3>{transacao.codref}</h3>
                                </div>
                                {transacao.final_cartao && transacao.tipo === 'Crédito' && (
                                    <div className="info" style={{ flex: '1', minWidth: '200px' }}>
                                        <p>Final do Cartão</p>
                                        <h3>{transacao.final_cartao}</h3>
                                    </div>
                                )}
                                <div className="info" style={{ flex: '1', minWidth: '200px' }}>
                                    <p>Parcelas</p>
                                    <h3>{transacao.parcelamento}</h3>
                                </div>
                            </div>

                            <div className="rowCenter" style={{ 
                                display: 'flex', 
                                flexWrap: 'wrap',
                                gap: '20px',
                                marginTop: '20px'
                            }}>
                                <div className="info" style={{ flex: '1', minWidth: '200px' }}>
                                    <p>Valor Bruto</p>
                                    <h3>R$ {Funcoes.float2Preco(transacao.valorbruto)}</h3>
                                </div>
                                <div className="info" style={{ flex: '1', minWidth: '200px' }}>
                                    <p>Taxas</p>
                                    <h3>R$ {Funcoes.float2Preco(transacao.taxas)}</h3>
                                </div>
                                <div className="info" style={{ flex: '1', minWidth: '200px' }}>
                                    <p>Valor Líquido</p>
                                    <h3>R$ {Funcoes.float2Preco(transacao.liquido)}</h3>
                                </div>
                            </div>

                            <div className="rowCenter" style={{ 
                                display: 'flex', 
                                flexWrap: 'wrap',
                                gap: '20px',
                                marginTop: '20px'
                            }}>
                                <div className="info" style={{ flex: '1', minWidth: '200px' }}>
                                    <p>Liberação do Valor</p>
                                    <h3>{transacao.liberacao}</h3>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Link to="/transacoes" className="btn btnVoltar">{'<'} Voltar</Link>
                </div>
            </div>
        </>
    )
}
