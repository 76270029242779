import axios from 'axios';

const ExossCardApiVersion = process.env.REACT_APP_WALLET_API_VERSION;
const ExossCardApiUrl = process.env.REACT_APP_WALLET_URL + "/api/";

// Configurar interceptor global
axios.interceptors.response.use(
    response => response,
    error => {
        if (error.response && error.response.status === 401) {
            sessionStorage.removeItem('userData');
            window.location.href = '/';
            return Promise.reject(error);
        }
        return Promise.reject(error);
    }
);

export default class ApiService {
    static ExossCardApiUrl() { return ExossCardApiUrl; }
    static ExossCardApiVersion() { return ExossCardApiVersion; }

    static getHeaders(token) {
        return {
            'Authorization': token ? 'Bearer ' + token : '',
            'Content-Type': 'application/json'
        }
    }

    static get(url, token) {
        const options = {
            url: `${ExossCardApiUrl}${url}`,
            method: 'GET',
            headers: this.getHeaders(token)
        };
        return axios(options);
    }

    static post(url, dados, token, method) {
        const options = {
            url: `${ExossCardApiUrl}${url}`,
            method: method ? method : 'POST',
            headers: this.getHeaders(token),
            data: dados
        };
        return axios(options);
    }

    static put(url, dados, token, method) {
        const options = {
            url: `${ExossCardApiUrl}${url}`,
            method: method ? method : 'PUT',
            headers: this.getHeaders(token),
            data: dados
        };
        return axios(options);
    }

    static getGeral(url, token) {
        return this.get(`geral/${url}`, token);
    }

    static postGeral(url, dados, token, method) {
        return this.post(`geral/${url}`, dados, token, method);
    }

    static getEmpresa(url, token) {
        return this.get(`empresa/${url}`, token);
    }

    static postEmpresa(url, dados, token, method) {
        return this.post(`empresa/${url}`, dados, token, method);
    }

    static customRequest(url, dados, metodo, token) {
        const options = {
            url: `${ExossCardApiUrl}${url}`,
            method: metodo,
            headers: this.getHeaders(token),
            data: dados ? dados : {}
        }
        return axios(options);
    }

    static handleErros(response) {
        if (response && response.status === 401) {
            sessionStorage.removeItem('userData');
            window.location.href = '/';
        }

        console.log(response);
        
        if (response && response.data && (response.data.message || response.data.mensagem) ) {
            return { response: response.data.message || response.data.mensagem };
        }
        return { response: 'Ocorreu um erro inesperado.' };
    }
}
