import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { PrivateRoute } from './Utils/PrivateRoute';
import './styles/buttons.css';

//Screens
import Login from './Screens/Login';
import Dashboard from './Screens/Content/Dashboard/Dashboard';
import CardStatement from './Screens/Content/CardStatement/CardStatement';
import CardStatementDetails from './Screens/Content/CardStatement/CardStatementDetails';
import DigitalAccount from './Screens/Content/DigitalAccount/DigitalAccount';
import DigitalAccountBalance from './Screens/Content/DigitalAccount/DigitalAccountBalance';
import DigitalAccountDetails from './Screens/Content/DigitalAccount/DigitalAccountDetails';
import DigitalAccountStatement from './Screens/Content/DigitalAccount/DigitalAccountStatement';
import Transfer from './Screens/Content/Transfer/Transfer';
import PaymentLinks from './Screens/Content/PaymentLinks/PaymentLinks';
import ReguasCobranca from './Screens/Content/ReguasCobranca/ReguasCobranca';
import Assinaturas from './Screens/Content/Assinaturas/Assinaturas';
import MinhaConta from './Screens/Content/MeusDados/MinhaConta';
import Configuracoes from './Screens/Content/MeusDados/Configuracoes';
import Taxas from './Screens/Content/MeusDados/Taxas';
import Cobrancas from './Screens/Content/Cobrancas/Cobrancas';

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route exact path="/" component={Login} />
      
      <PrivateRoute path="/home" component={Dashboard} />
      
      <PrivateRoute exact path="/contas-digitais" component={DigitalAccount} />
      
      <PrivateRoute exact path="/contas-digitais/detalhes" component={DigitalAccountDetails} />
      
      <PrivateRoute exact path="/contas-digitais/extrato" component={DigitalAccountStatement} />
      
      <PrivateRoute exact path="/contas-digitais/saldo_liberar" component={DigitalAccountBalance} />
      
      <PrivateRoute path="/transferencia" component={Transfer} />
      
      <PrivateRoute exact path="/transacoes" component={CardStatement} />
      
      <PrivateRoute path="/transacoes/detalhes" component={CardStatementDetails} />
      
      <PrivateRoute path="/payment-links" component={PaymentLinks} />
      
      <PrivateRoute path="/reguas-cobranca" component={ReguasCobranca} />
      
      <PrivateRoute path="/assinaturas" component={Assinaturas} />
      
      <PrivateRoute path="/meus-dados/minha-conta" component={MinhaConta} />
      <PrivateRoute path="/meus-dados/configuracoes" component={Configuracoes} />
      <PrivateRoute path="/meus-dados/taxas" component={Taxas} />
      
      <PrivateRoute exact path="/cobrancas" component={Cobrancas} />
    </Switch>
  </BrowserRouter>,
  document.getElementById('root')
);
