import React, { useState, useEffect } from 'react';

import iconSenha from '../../../resources/img/icon-senha.svg';
import SideMenu from '../../../Components/SideMenu';
import Header from '../../../Components/Header';
import Loading from '../../../Components/LoaderScreen';
import Alert from '../../../Components/Alert';
import Modal from '../../../Components/Modal';
import Input from '../../../Components/Input';
import { cpfMask, cnpjMask } from '../../../Utils/mask';
import Funcoes from '../../../Utils/functions';
import ApiService from '../../../Utils/services';

export default function Transfer() {

    const busca = [
        {
            "text": "CPF / CNPJ",
            "value": "cpfcnpj"
        },
        {
            "text": "Número da conta",
            "value": "numero"
        }
    ];

    const [redirect, setRedirect] = useState(false);
    const [path, setPath] = useState('');

    const [loading, setLoading] = useState(false);
    const [modal, setModal] = useState(false);
    const [alert, setAlert] = useState(false);
    const [alertText, setAlertText] = useState('');
    const [alertType, setAlertType] = useState(2);
    const [filterBy, setFilterBy] = useState('cpfcnpj');
    const [filter, setFilter] = useState('');

    const [showPessoa, setShowPessoa] = useState(false);
    const [pessoa, setPessoa] = useState({});
    const [valor, setValor] = useState(0);

    const user = JSON.parse(sessionStorage.getItem("userData"));

    const buscarConta = () => {
        if(filter == ''){
            setPessoa({});
            setShowPessoa(false);
            setAlertText('Preencha o campo corretamente!');
            setTimeout(() => {
                setAlert(true);
            }, 300);
        }else{
            setLoading(true);
            var url = 'transferencia/contadigital';
            if(filter !== '') url = url + '?' + filterBy + '=' + filter;
            ApiService.get(url, user.token)
                .then(result => {
                    const response = result.data;
                    setPessoa(response.data);
                    setShowPessoa(true);
                    setLoading(false);
                })
                .catch(err => {
                    const result = ApiService.handleErros(err.response);
                    if (result.status === "unauthorized") {
                        sessionStorage.removeItem('userData');
                        setPath('/');
                        setPessoa({});
                        setLoading(false);
                        setAlertText(result.response);
                        setTimeout(() => {
                            setAlert(true);
                        }, 300);
                    }else if(result.status == "badRequest"){
                        setAlertText('Nenhuma conta digital foi encontrada!');
                        setPessoa({});
                        setShowPessoa(false);
                        setLoading(false);
                        setTimeout(() => {
                            setAlert(true);
                        }, 300);
                    }
                })
            }
        }

    const handleValor = (event, maskedvalue, floatvalue) => {
       setValor(floatvalue);
    }

    const handleTransfer = () => {
        setLoading(true);
        const data = {
            conta_destino: pessoa.id,
            valor: valor
        };

        ApiService.post('transferencia/contadigital', data, user.token)
            .then(result => {
                setLoading(false);
                setAlertType(1);
                setAlertText('Transferência realizada com sucesso!');
                setTimeout(() => {
                    setAlert(true);
                }, 300);
                setValor(0);
                setShowPessoa(false);
                setPessoa({});
                setFilter('');
            })
            .catch(err => {
                const result = ApiService.handleErros(err.response);
                if (result.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    setPath('/');
                    setLoading(false);
                    setAlertText(result.response);
                    setTimeout(() => {
                        setAlert(true);
                    }, 300);
                } else {
                    setLoading(false);
                    setAlertType(2);
                    setAlertText(result.response);
                    setTimeout(() => {
                        setAlert(true);
                    }, 300);
                }
            });
    }

    return (
        <>
            <Loading show={loading} />
            <Alert show={alert} texto={alertText} tipo={alertType} action={() => { setAlert(false) }} />
            <Modal show={modal} title="Confirmar Transferência" onClose={() => setModal(false)}>
                <div className="modalBody">
                    <div className="form-group">
                        <label>Valor</label>
                        <div className="form-control-static">
                            {Funcoes.float2Preco(valor)}
                        </div>
                    </div>
                    <div className="modal-actions">
                        <button 
                            className="btn secondary" 
                            onClick={() => setModal(false)}
                        >
                            Cancelar
                        </button>
                        <button 
                            className="btn primary"
                            onClick={() => { 
                                setModal(false); 
                                handleTransfer();
                            }}
                        >
                            Confirmar
                        </button>
                    </div>
                </div>
            </Modal>
            <SideMenu menuAtivo={3} />

            <div className="body">
                <Header />

                <div className="transfer mgDefault">
                    <div className="headerBtn">
                        <div>
                            <h1>Transferência entre Contas</h1>
                            <p className="descricao">Preencha abaixo os dados da conta de destino</p>
                        </div>
                    </div>

                    <div className="panel">
                        <div className="panelHeader">
                            <h3>Conta de Saída</h3>
                        </div>
                        <div className="panelBody">
                            <div className="rowCenter">
                                <div className="info">
                                    <p>Nome/Razão:</p>
                                    <h3>{user.data.conta_digital.nomerazao}</h3>
                                </div>
                                <div className="info">
                                    <p>CPF/CNPJ:</p>
                                    <h3>{user.data.conta_digital.cpfcnpj}</h3>
                                </div>
                                <div className="info">
                                    <p>Nº da Conta:</p>
                                    <h3>{user.data.conta_digital.numero}</h3>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="panel">
                        <div className="panelHeader">
                            <h3>Conta de Destino</h3>
                        </div>
                        <div className="panelBody">
                            <div className="multiInput">
                                <Input type="select" label="Buscar por" onChange={e => { setFilterBy(e.target.value); setFilter(''); setShowPessoa(false); setPessoa({}); }} optionsData={busca} divStyle={{ maxWidth: 165 }} />
                                <Input type="text" label="Digite o que procura..." value={filter} onChange={e => setFilter(filterBy === "cpfcnpj" ? (e.target.value.length <= 14 ? cpfMask(e.target.value) : cnpjMask(e.target.value)) : e.target.value)} maxLength={filterBy === 'cpfcnpj' ? 18 : 20} divStyle={{ maxWidth: 367 }} />
                                <button className="btn" onClick={() => { buscarConta() }}>Buscar</button>
                            </div>
                            { showPessoa ? 
                                <div className="rowCenter" style={{ marginBottom: 22 }}>
                                    <div className="info">
                                        <p>Nome/Razão:</p>
                                        <h3>{pessoa.nomerazao}</h3>
                                    </div>
                                    <div className="info">
                                        <p>CPF/CNPJ:</p>
                                        <h3>{pessoa.cpfcnpj}</h3>
                                    </div>
                                    <div className="info">
                                        <p>Nº da Conta:</p>
                                        <h3>{pessoa.numero}</h3>
                                    </div>
                                </div>
                                : ''
                            }

                            { showPessoa ? 
                                <Input type="money" label="Valor da Transferência" value={valor} onChangeEvent={handleValor} divStyle={{ maxWidth: 180 }} />
                                : ''
                            }
                        </div>
                    </div>

                    <button 
                        className="btn btnAction" 
                        onClick={() => {
                            if (!showPessoa) {
                                setAlertType(2);
                                setAlertText('Selecione uma conta de destino!');
                                setTimeout(() => {
                                    setAlert(true);
                                }, 300);
                                return;
                            }
                            if (!valor || valor <= 0) {
                                setAlertType(2);
                                setAlertText('Informe um valor válido!');
                                setTimeout(() => {
                                    setAlert(true);
                                }, 300);
                                return;
                            }
                            setModal(true);
                        }}
                    >
                        Transferir
                    </button>
                </div>
            </div>
        </>
    )
}
